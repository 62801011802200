import {mix} from 'polished';
import {Option as OptionInterface} from '../../contexts/Quote';
import {OPTION_GROUP_CHECK_TYPE} from './static';

const commonRules = {
  groovedBlowhole: 'DBC_Event_rainure',
  antibacterialVarnish: 'OPT_foption01',
};

export enum PRODUCT_CATEGORY {
  CLASSIQUE = 'Classique',
  DEPORTE = 'Deporte',
  PROTECTION = 'Protection',
}

export enum CLASSIQUE_SHAPE {
  CANULE = 'Classique_Canule',
  CANULE_EPAULEMENT = 'Classique_CanuleEpaulement',
  CANULE_DEMI_EPAULEMENT = 'Classique_CanuleDemiEpaulement',
  CANULE_QUART_EPAULEMENT = 'Classique_CanuleQuartEpaulement',
  SQUELETTE = 'Classique_Squelette',
  FOND_CONQUE = 'Classique_FondConque',
  DEMI_CONQUE = 'Classique_DemiConque',
  PINCE_CRABE = 'Classique_PinceCrabe',
}

export enum CLASSIQUE_MATERIAL {
  RESINE_3D = 'Classique_Resine3D',
  PLATINUM_25_SH = 'Classique_Platinum25sh',
  PLATINUM_40_SH = 'Classique_Platinum40sh',
  PLATINUM_60_SH = 'Classique_Platinum60sh',
  THERMOTEC = 'Classique_Thermotec',
}
export const classiqueShapeRule: {
  [material in CLASSIQUE_SHAPE]:
    | string
    | ((material: CLASSIQUE_MATERIAL) => string);
} = {
  [CLASSIQUE_SHAPE.CANULE]: 'canuleSimple',
  [CLASSIQUE_SHAPE.CANULE_EPAULEMENT]: 'canuleEpaule',
  [CLASSIQUE_SHAPE.CANULE_DEMI_EPAULEMENT]: 'canuleDemiEpaule',
  [CLASSIQUE_SHAPE.CANULE_QUART_EPAULEMENT]: 'canuleQuartEpaule',
  [CLASSIQUE_SHAPE.SQUELETTE]: 'canuleSquelette',
  [CLASSIQUE_SHAPE.FOND_CONQUE]: 'canuleFondConque',
  [CLASSIQUE_SHAPE.DEMI_CONQUE]: 'canuleFondConque',
  [CLASSIQUE_SHAPE.PINCE_CRABE]: (material: CLASSIQUE_MATERIAL) =>
    material === CLASSIQUE_MATERIAL.RESINE_3D
      ? 'canulePince'
      : 'canulePinceCrabe',
};

export const classiqueMaterialPrefix: {
  [material in CLASSIQUE_MATERIAL]?: string;
} = {
  [CLASSIQUE_MATERIAL.RESINE_3D]: 'RES',
  [CLASSIQUE_MATERIAL.PLATINUM_25_SH]: 'SIL',
  [CLASSIQUE_MATERIAL.PLATINUM_40_SH]: 'SIL',
  [CLASSIQUE_MATERIAL.PLATINUM_60_SH]: 'SIL',
};

export const classiqueMaterialRule: {
  [material in CLASSIQUE_MATERIAL]: (
    materialPrefix: string,
    shape: CLASSIQUE_SHAPE,
  ) => string;
} = {
  [CLASSIQUE_MATERIAL.RESINE_3D]: (m) =>
    `/EMB_RES_MAT_resineIP:/EMB_RES_FRM_${m}:/EMB_RES_MAT_ip:/EMB_RES_FRM_${m}:/`,
  [CLASSIQUE_MATERIAL.PLATINUM_25_SH]: (m) =>
    `/EMB_SIL_MAT_platinium25:/EMB_SIL_FRM_${m}:/EMB_RES_MAT_platinium25:/EMB_RES_FRM_${m}:/`,
  [CLASSIQUE_MATERIAL.PLATINUM_40_SH]: (m) =>
    `/EMB_SIL_MAT_platinium40:/EMB_SIL_FRM_${m}:/EMB_RES_MAT_platinium40:/EMB_RES_FRM_${m}:/`,
  [CLASSIQUE_MATERIAL.PLATINUM_60_SH]: (m) =>
    `/EMB_SIL_MAT_platinium60:/EMB_SIL_FRM_${m}:/EMB_RES_MAT_platinium60:/EMB_RES_FRM_${m}:/`,
  [CLASSIQUE_MATERIAL.THERMOTEC]: (_, shape) => {
    switch (shape) {
      case CLASSIQUE_SHAPE.CANULE:
        return '/EMB_SIL_Autre:/EMB_RES_MAT_thermotec:/EMB_RES_FRM_canuleSimple:/';
      case CLASSIQUE_SHAPE.CANULE_EPAULEMENT:
        return '/EMB_SIL_Autre:/EMB_RES_MAT_thermotec:/EMB_RES_FRM_canuleEpaule:/';
      case CLASSIQUE_SHAPE.CANULE_DEMI_EPAULEMENT:
        return '/EMB_RES_MAT_thermotec:/EMB_RES_FRM_canuleDemiEpaule:/';
      case CLASSIQUE_SHAPE.CANULE_QUART_EPAULEMENT:
        return '/EMB_RES_MAT_thermotec:/EMB_RES_FRM_canuleQuartEpaule:/';
      case CLASSIQUE_SHAPE.SQUELETTE:
        return '/EMB_SIL_Autre:/EMB_RES_MAT_thermotec:/EMB_RES_FRM_canuleSquelette:/';
      case CLASSIQUE_SHAPE.FOND_CONQUE:
        return '/EMB_SIL_Autre:/EMB_RES_MAT_thermotec:/EMB_RES_FRM_canuleFondConque:/';
      case CLASSIQUE_SHAPE.DEMI_CONQUE:
        return '/EMB_SIL_Autre:/EMB_RES_MAT_thermotec:/EMB_RES_FRM_canuleFondDmConque:/';
      case CLASSIQUE_SHAPE.PINCE_CRABE:
        return '/EMB_SIL_Autre:/EMB_RES_MAT_thermotec:/EMB_RES_FRM_canulePince:/';
      default:
        return '';
    }
  },
};

export enum DEPORTE_BRAND {
  SIEMENS = 'Deporte_Siemens',
  OTICON = 'Deporte_Oticon',
  BELTONE = 'Deporte_Beltone',
  WIDEX = 'Deporte_Widex',
  PHONAK = 'Deporte_Phonak',
  STARKEY = 'Deporte_Starkey',
}

export enum DEPORTE_SHAPE {
  DEPORTE = 'Deporte_Embouts',
  CANULE_AERO = 'Deporte_CanuleAero',
  COQUE = 'Deporte_Coque',

  DEPORTE_CANULE = 'Deporte_Canule',
  DEPORTE_CANULE_CIC = 'Deporte_Canule_CIC',
  DEPORTE_SKELETON = 'Deporte_Squelette',
  DEPORTE_CRAB_PINCER = 'Deporte_Pince_de_crabe',
  DEPORTE_CONQUE = 'Deporte_conque',
  DEPORTE_HALF_CONQUE = 'Deporte_demi_conque',

  AERO_CANULE = 'Aero_Canule',
  AERO_SKELETON = 'Aero_Squelette',

  CRAB_CANULE = 'Crab_Canule',
}

export enum DEPORTE_MATERIAL {
  RESINE_3D = 'Deporte_Canule_Resine3D',
  SILICON_PLATNIUM_40 = 'Deporte_Canule_SiliconPlatinum40',
  THERMOTEC = 'Deporte_Canule_Thermotec',
}

export const deporteMaterialRule: {[material in DEPORTE_MATERIAL]: string} = {
  [DEPORTE_MATERIAL.RESINE_3D]: 'resine',
  [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]: 'silicone',
  [DEPORTE_MATERIAL.THERMOTEC]: 'thermotec',
};

export const deporteNonMicroRules: {
  [material in DEPORTE_SHAPE]?: {[material in DEPORTE_MATERIAL]?: string};
} = {
  [DEPORTE_SHAPE.DEPORTE_CANULE_CIC]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_ip:/EMB_MCE_FRM_DPT_canuleCic:/',
    [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]:
      '/EMB_MCE_MAT_platinium40:/EMB_MCE_FRM_DPT_canuleCic:/',
  },
  [DEPORTE_SHAPE.DEPORTE_CANULE]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_resine:/EMB_MCE_FRM_DPT_canule:/EMB_MCE_MAT_ip:/EMB_MCE_FRM_DPT_canuleSimple:/',
    [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]:
      '/EMB_MCE_MAT_silicone:/EMB_MCE_FRM_DPT_canule:/EMB_MCE_MAT_platinium40:/EMB_MCE_FRM_DPT_canuleSimple:/',
    [DEPORTE_MATERIAL.THERMOTEC]:
      '/EMB_MCE_Autre:/EMB_MCE_MAT_thermotec:/EMB_MCE_FRM_DPT_canuleSimple:/',
  },
  [DEPORTE_SHAPE.DEPORTE_SKELETON]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_resine:/EMB_MCE_FRM_DPT_squelette:/EMB_MCE_MAT_ip:/EMB_MCE_FRM_DPT_canuleSquelette:/',
    [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]:
      '/EMB_MCE_MAT_silicone:/EMB_MCE_FRM_DPT_squelette:/EMB_MCE_MAT_platinium40:/EMB_MCE_FRM_DPT_canuleSquelette:/',
    [DEPORTE_MATERIAL.THERMOTEC]:
      '/EMB_MCE_Autre:/EMB_MCE_MAT_thermotec:/EMB_MCE_FRM_DPT_canuleSquelette:/',
  },
  [DEPORTE_SHAPE.DEPORTE_CRAB_PINCER]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_resine:/EMB_MCE_FRM_DPT_pinceCrabe:/EMB_MCE_MAT_ip:/EMB_MCE_FRM_DPT_canulePince:/',
    [DEPORTE_MATERIAL.THERMOTEC]:
      '/EMB_MCE_Autre:/EMB_MCE_MAT_thermotec:/EMB_MCE_FRM_DPT_canulePince:/',
  },
  [DEPORTE_SHAPE.DEPORTE_CONQUE]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_resine:/EMB_MCE_FRM_DPT_fondConque:/EMB_MCE_MAT_ip:/EMB_MCE_FRM_DPT_canuleFondConque:/',
    [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]:
      '/EMB_MCE_MAT_silicone:/EMB_MCE_FRM_DPT_fondConque:/EMB_MCE_MAT_platinium40:/EMB_MCE_FRM_DPT_canuleFondConque:/',
    [DEPORTE_MATERIAL.THERMOTEC]:
      '/EMB_MCE_Autre:/EMB_MCE_MAT_thermotec:/EMB_MCE_FRM_DPT_canulefondConque:/',
  },
  [DEPORTE_SHAPE.DEPORTE_HALF_CONQUE]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_resine:/EMB_MCE_FRM_DPT_fondConque:/EMB_MCE_MAT_ip:/EMB_MCE_FRM_DPT_canuleFondConque:/',
    [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]:
      '/EMB_MCE_MAT_silicone:/EMB_MCE_FRM_DPT_fondConque:/EMB_MCE_MAT_platinium40:/EMB_MCE_FRM_DPT_canuleFondConque:/',
    [DEPORTE_MATERIAL.THERMOTEC]:
      '/EMB_MCE_Autre:/EMB_MCE_MAT_thermotec:/EMB_MCE_FRM_DPT_canuleFondDmConque:/',
  },
  [DEPORTE_SHAPE.CRAB_CANULE]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_resine:/EMB_MCE_FRM_COQ_canule:/EMB_MCE_MAT_ip:/EMB_MCE_FRM_COQ_canuleSimple:/',
  },
};

export const deporteMicroRules: {
  [material in DEPORTE_SHAPE]?: {[material in DEPORTE_MATERIAL]?: string};
} = {
  [DEPORTE_SHAPE.DEPORTE_CANULE_CIC]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_ip:/EMB_MCE_FRM_AER_canuleCic:/',
    [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]:
      '/EMB_MCE_MAT_platinium40:/EMB_MCE_FRM_AER_canuleCic:/',
  },
  [DEPORTE_SHAPE.DEPORTE_CANULE]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_resine:/EMB_MCE_FRM_AER_canule:/EMB_MCE_MAT_ip:/EMB_MCE_FRM_AER_canuleSimple:/',
    [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]:
      '/EMB_MCE_MAT_platinium40:/EMB_MCE_FRM_AER_canuleSimple:/',
    [DEPORTE_MATERIAL.THERMOTEC]:
      '/EMB_MCE_Autre:/EMB_MCE_MAT_thermotec:/EMB_MCE_FRM_AER_canuleSimple:/',
  },
  [DEPORTE_SHAPE.DEPORTE_SKELETON]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_resine:/EMB_MCE_FRM_AER_squelette:/EMB_MCE_MAT_ip:/EMB_MCE_FRM_AER_canuleSquelette:/',
    [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]:
      '/EMB_MCE_MAT_platinium40:/EMB_MCE_FRM_AER_canuleSquelette:/',
    [DEPORTE_MATERIAL.THERMOTEC]:
      '/EMB_MCE_Autre:/EMB_MCE_MAT_thermotec:/EMB_MCE_FRM_AER_canuleSquelette:/',
  },
  [DEPORTE_SHAPE.DEPORTE_CRAB_PINCER]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_ip:/EMB_MCE_FRM_AER_canulePince:/',
    [DEPORTE_MATERIAL.THERMOTEC]:
      '/EMB_MCE_Autre:/EMB_MCE_MAT_thermotec:/EMB_MCE_FRM_AER_canulePince:/',
  },
  [DEPORTE_SHAPE.DEPORTE_CONQUE]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_ip:/EMB_MCE_FRM_AER_canuleFondConque:/',
    [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]:
      '/EMB_MCE_MAT_platinium40:/EMB_MCE_FRM_AER_canuleFondConque:/',
    [DEPORTE_MATERIAL.THERMOTEC]:
      '/EMB_MCE_Autre:/EMB_MCE_MAT_thermotec:/EMB_MCE_FRM_AER_canuleFondConque:/',
  },
  [DEPORTE_SHAPE.DEPORTE_HALF_CONQUE]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_ip:/EMB_MCE_FRM_AER_canuleFondConque:/',
    [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]:
      '/EMB_MCE_MAT_platinium40:/EMB_MCE_FRM_AER_canuleFondConque:/',
    [DEPORTE_MATERIAL.THERMOTEC]:
      '/EMB_MCE_Autre:/EMB_MCE_MAT_thermotec:/EMB_MCE_FRM_AER_canuleFondDmConque:/',
  },
  [DEPORTE_SHAPE.CRAB_CANULE]: {
    [DEPORTE_MATERIAL.RESINE_3D]:
      '/EMB_MCE_MAT_resine:/EMB_MCE_FRM_COQ_canule:/EMB_MCE_MAT_ip:/EMB_MCE_FRM_COQ_canuleSimple:/',
  },
};

export const deporteShapeRule: {
  [material in DEPORTE_SHAPE]?: string;
} = {
  [DEPORTE_SHAPE.DEPORTE_CANULE]: 'DPT_canule',
  [DEPORTE_SHAPE.DEPORTE_CANULE_CIC]: 'DPT_canuleCic',
  [DEPORTE_SHAPE.DEPORTE_SKELETON]: 'DPT_squelette',
  [DEPORTE_SHAPE.DEPORTE_CRAB_PINCER]: 'DPT_pinceCrabe',
  [DEPORTE_SHAPE.DEPORTE_CONQUE]: 'DPT_fondConque',
  [DEPORTE_SHAPE.DEPORTE_HALF_CONQUE]: 'DPT_fondConque',
  [DEPORTE_SHAPE.AERO_CANULE]: 'AER_canule',
  [DEPORTE_SHAPE.AERO_SKELETON]: 'AER_squelette',
  [DEPORTE_SHAPE.CRAB_CANULE]: 'COQ_canule',
};

export enum PROTECTION_SHAPE {
  SOMMEIL = 'Protection_Sommeil',
  SWEETNIGHT = 'Protection_Sweetnight',
  SWEETNIGHT_CANULE = 'Protection_SweetnightCanule',
  AQUASTOP = 'Protection_Aquastop',
  OBTURATEUR = 'Protection_Obturateur',
  GAME_EAR = 'Protection_GameEar',
  PASSTOP_OR = 'Protection_PasstopOr',
  AQUASON = 'Protection_Aquason',
  PASSTOP_EP2 = 'Protection_PasstopEP2',
  PASSTOP_A = 'Protection_PasstopA',
  PASSTOP_TIR_CHASSE = 'Protection_PasstopTirChasse',
  STOPGUN_E = 'Protection_StopGunE',
  STOPGUN_FLEX = 'Protection_StopGunFlex',

  PASSTOP_STOPGUN = 'Protection_PasstopStopGun',

  PIANISSIMO = 'Protection_Pianissimo',
  EARBACK_MUSIC = 'Protection_EarbackMusic',
  EAR = 'Protection_Ear',
  PASSTOP_OS = 'Protection_PasstopOS',

  OREILLETTE = 'Protection_Oreillette',
  PASSTOP_OS_CONQUE = 'Protection_PasstopOSConque',
  OBTURATEUR_COM = 'Protection_ObturateurCom',
  SECRETEAR = 'Protection_SecretEar',
}

export enum COLOR {
  RED = 'RED',
  BLUE = 'BLUE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  WHITE = 'WHITE',
  BLACK = 'BLACK',
  ORANGE = 'ORANGE',
  TRANSLUCENT = 'TRANSLUCENT',
  COLORLESS_TRANSLUCENT = 'COLORLESS_TRANSLUCENT',
  ROSE_TRANSLUCENT = 'ROSE_TRANSLUCENT',
  BROWN = 'BROWN',
  ROSE = 'ROSE',
  PASSTOP_OR_BLUE = 'PASSTOP_OR_BLUE',

  ROSE_TRANSPARENT = 'ROSE_TRANSPARENT',
  BLUE_PHOSOPHORESCENT = 'BLUE_PHOSOPHORESCENT',
  RED_PHOSOPHORESCENT = 'RED_PHOSOPHORESCENT',
  ORANGE_PHOSOPHORESCENT = 'ORANGE_PHOSOPHORESCENT',
  YELLOW_PHOSOPHORESCENT = 'YELLOW_PHOSOPHORESCENT',
  GREEN_PHOSOPHORESCENT = 'GREEN_PHOSOPHORESCENT',
  PINK_PHOSOPHORESCENT = 'PINK_PHOSOPHORESCENT',

  BLACK_OPAQUE = 'BLACK_OPAQUE',
  GREEN_TRANSLUCENT = 'GREEN_TRANSLUCENT',
  BLUE_TRANSLUCENT = 'BLUE_TRANSLUCENT',

  YELLOW_FLUO = 'YELLOW_FLUO',
  PURPLE = 'PURPLE',
  FLESH = 'FLESH',
}

export const colorLabels: {[key in COLOR]: string} = {
  [COLOR.RED]: 'Rouge',
  [COLOR.BLUE]: 'Bleu',
  [COLOR.PASSTOP_OR_BLUE]: 'Bleu',
  [COLOR.YELLOW]: 'Jaune',
  [COLOR.GREEN]: 'Vert',
  [COLOR.WHITE]: 'Blanc',
  [COLOR.BLACK]: 'Noir',
  [COLOR.ORANGE]: 'Orange',
  [COLOR.TRANSLUCENT]: 'Translucide',
  [COLOR.COLORLESS_TRANSLUCENT]: 'Incolore translucide',
  [COLOR.ROSE_TRANSLUCENT]: 'Rosé translucide',
  [COLOR.ROSE]: 'Rosé',
  [COLOR.BROWN]: 'Marron',

  [COLOR.ROSE_TRANSPARENT]: 'Rosé Transparent',
  [COLOR.BLUE_PHOSOPHORESCENT]: 'Bleu phosphorescent',
  [COLOR.RED_PHOSOPHORESCENT]: 'Rouge phosphorescent',
  [COLOR.ORANGE_PHOSOPHORESCENT]: 'Orange phosphorescent',
  [COLOR.YELLOW_PHOSOPHORESCENT]: 'Jaune phosphorescent',
  [COLOR.GREEN_PHOSOPHORESCENT]: 'Vert phosphorescent',
  [COLOR.PINK_PHOSOPHORESCENT]: 'Rose phosphorescent',

  [COLOR.BLACK_OPAQUE]: 'Noir opaque',
  [COLOR.GREEN_TRANSLUCENT]: 'Vert translucide',
  [COLOR.BLUE_TRANSLUCENT]: 'Bleu translucide',

  [COLOR.YELLOW_FLUO]: 'Jaune fluo',
  [COLOR.PURPLE]: 'Violet',
  [COLOR.FLESH]: 'Chair',
};

export const colorHex: {[key in COLOR]: string} = {
  [COLOR.RED]: '#FF3C44',
  [COLOR.BLUE]: '#2922ff',
  [COLOR.PASSTOP_OR_BLUE]: '#2922ff',
  [COLOR.GREEN]: '#2BCF29',
  [COLOR.YELLOW]: '#dcc600',
  [COLOR.WHITE]: '#ffffff',
  [COLOR.BLACK]: '#000000',
  [COLOR.ORANGE]: '#ff8500',

  [COLOR.TRANSLUCENT]: mix(0.5, '#ffffff', 'white'),
  [COLOR.COLORLESS_TRANSLUCENT]: mix(0.5, '#ffffff', 'white'),
  [COLOR.ROSE_TRANSLUCENT]: mix(0.5, '#ffc1cb', 'white'),
  [COLOR.ROSE]: '#ffc1cb',
  [COLOR.BROWN]: '#bf6000',

  [COLOR.BLACK_OPAQUE]: '#000000',
  [COLOR.GREEN_TRANSLUCENT]: mix(0.5, '#2BCF29', 'white'),
  [COLOR.BLUE_TRANSLUCENT]: mix(0.5, '#2922ff', 'white'),

  [COLOR.ROSE_TRANSPARENT]: mix(0.5, '#ffc1cb', 'white'),
  [COLOR.BLUE_PHOSOPHORESCENT]: '#0035ff',
  [COLOR.RED_PHOSOPHORESCENT]: '#ff002d',
  [COLOR.ORANGE_PHOSOPHORESCENT]: '#ff5d00',
  [COLOR.YELLOW_PHOSOPHORESCENT]: '#d5dc00',
  [COLOR.GREEN_PHOSOPHORESCENT]: '#0acf00',
  [COLOR.PINK_PHOSOPHORESCENT]: '#ff00ff',

  [COLOR.YELLOW_FLUO]: '#d5dc00',
  [COLOR.PURPLE]: '#b800ff',

  [COLOR.FLESH]: '#ffc1cb',
};

export enum CORD_COLOR {
  BLACK = 'BLACK',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',
}

export const cordColorLabels: {[key in CORD_COLOR]: string} = {
  [CORD_COLOR.BLACK]: 'Cordon Noir',
  [CORD_COLOR.GREEN]: 'Cordon Vert',
  [CORD_COLOR.BLUE]: 'Cordon Bleu',
  [CORD_COLOR.ORANGE]: 'Cordon Orange',
};

export const cordColorHex: {[key in CORD_COLOR]: string} = {
  [CORD_COLOR.BLACK]: '#000000',
  [CORD_COLOR.GREEN]: '#2BCF29',
  [CORD_COLOR.BLUE]: '#2922ff',
  [CORD_COLOR.ORANGE]: '#ff8500',
};

export const cordColorRules: {[key in CORD_COLOR]: string} = {
  [CORD_COLOR.BLACK]: '/EMB_ART_paire_force:/EMB_EPR_OPT_cordonEP2n:/',
  [CORD_COLOR.GREEN]: '/EMB_ART_paire_force:/EMB_EPR_OPT_cordonEP2v:/',
  [CORD_COLOR.BLUE]: '/EMB_ART_paire_force:/EMB_EPR_OPT_cordonEP2b:/',
  [CORD_COLOR.ORANGE]: '/EMB_ART_paire_force:/EMB_EPR_OPT_cordonEP2o:/',
};

export enum EARBACK_PRODUCT {
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  THREE_T = 'THREE_T',
  FOUR_T = 'FOUR_T',
}

export const earbackProductLabels: {[key in EARBACK_PRODUCT]: string} = {
  [EARBACK_PRODUCT.ONE]: 'Earback music 1 voie',
  [EARBACK_PRODUCT.TWO]: 'Earback music 2 voies',
  [EARBACK_PRODUCT.THREE]: 'Earback music 3 voies',
  [EARBACK_PRODUCT.THREE_T]: 'Earback music 3T S BASS',
  [EARBACK_PRODUCT.FOUR_T]: 'Earback music 4T S BASS',
};

export const earbackProductRules: {[key in EARBACK_PRODUCT]: string} = {
  [EARBACK_PRODUCT.ONE]: '/EMB_ATR_EAR_voie1:/',
  [EARBACK_PRODUCT.TWO]: '/EMB_ATR_EAR_voie2:/',
  [EARBACK_PRODUCT.THREE]: '/EMB_ATR_EAR_voie3:/',
  [EARBACK_PRODUCT.THREE_T]: '/EMB_ATR_EAR_sBass:',
  [EARBACK_PRODUCT.FOUR_T]: '/EMB_ATR_EAR_ sBass4:/',
};

export enum EARBACK_COLORATION {
  INTEGRAL = 'INTEGRAL',
  PLATE = 'PLATE',
}

export const earbackColorationLabels: {[key in EARBACK_COLORATION]: string} = {
  [EARBACK_COLORATION.INTEGRAL]: ' Coloration intégrale surface',
  [EARBACK_COLORATION.PLATE]: 'Coloration face plate',
};

export const earbackColorationRules: {[key in EARBACK_COLORATION]: string} = {
  [EARBACK_COLORATION.INTEGRAL]: '/EMB_ATR_autre:/EMB_ATR_OPT_coloration:/',
  [EARBACK_COLORATION.PLATE]: '/EMB_ATR_autre:/EMB_ATR_OPT_colorationFP:/',
};

export enum FILTER {
  DB_10 = 'DB_10',
  DB_15 = 'DB_15',
  DB_25 = 'DB_25',
  DB_30 = 'DB_30',

  SNR22 = 'SNR22',
  SNR24 = 'SNR24',

  SNR21 = 'SNR21',
  SNR26 = 'SNR26',
}

export const filterLabels: {[key in FILTER]: string} = {
  [FILTER.DB_10]: '-10 dB (non homologué)',
  [FILTER.DB_15]: '-15 dB',
  [FILTER.DB_25]: '-25 dB',
  [FILTER.DB_30]: '-30 dB',

  [FILTER.SNR22]: 'T3 (industries de production) SNR22',
  [FILTER.SNR24]: 'T4 (industries lourdes) SNR24',

  [FILTER.SNR21]: 'C2 (usage privé) SNR21',
  [FILTER.SNR26]: 'C3 (industrie de conditionnement) SNR26',
};

export const required = {required: true};
export const unique = {unique: true};
export const requiredAndUnique = {...required, ...unique};

export enum SURFACE {
  ANTIBACTERIAL = 'ANTIBACTERIAL',
  MATTE = 'MATTE',
  SANDED = 'SANDED',
}
export const surfaceLabels: {[key in SURFACE]: string} = {
  [SURFACE.ANTIBACTERIAL]: 'Vernis antibactérien',
  [SURFACE.MATTE]: 'Vernis mat',
  [SURFACE.SANDED]: 'Sablage',
};
export const surfaceRules: {[key in SURFACE]?: string} = {
  [SURFACE.ANTIBACTERIAL]: commonRules.antibacterialVarnish,
};

export enum OBTURATEUR_PRODUCT_TYPE {
  ANTI_NOISE = 'ANTI_NOISE',
  ANTI_WATER = 'ANTI_WATER',
}

export const obturateurProductTypeLabels: {
  [key in OBTURATEUR_PRODUCT_TYPE]: string;
} = {
  [OBTURATEUR_PRODUCT_TYPE.ANTI_NOISE]:
    'Anti-Bruit (étui de rangement classique)',
  [OBTURATEUR_PRODUCT_TYPE.ANTI_WATER]: 'Anti-Eau (étui de rangement étanche)',
};

export enum PASSTOP_OR_PRODUCT_TYPE {
  R1 = 'R1',
  R2 = 'R2',
  R3 = 'R3',
}

export enum FINISH {
  SHINY = 'SHINY',
  MATTE_SANDED = 'MATTE_SANDED',
  SANDED_OUTSIDE = 'SANDED_OUTSIDE',
  VARNISH = 'VARNISH',
  UV_VARNISH = 'UV_VARNISH',
  WITHOUT_VARNISH = 'WITHOUT_VARNISH',
}

export const finishLabels: {[key in FINISH]: string} = {
  [FINISH.SHINY]: 'Poli',
  [FINISH.MATTE_SANDED]: 'Sablage complet',
  [FINISH.SANDED_OUTSIDE]: 'Sablage extérieur',
  [FINISH.VARNISH]: 'Vernis',
  [FINISH.UV_VARNISH]: 'Vernis UV',
  [FINISH.WITHOUT_VARNISH]: 'Non vernis',
};

export const finishRules: {[key in FINISH]?: string} = {
  [FINISH.UV_VARNISH]: '/EMB_RES_OPT_vernis:/EMB_MCE_OPT_vernis:/',
};

export enum PASSTOP_OS_PRODUCT_TYPE {
  S = 'S',
  S1 = 'S1',
  S2 = 'S2',
  S3 = 'S3',
}

export const passtopOsLabels: {[key in PASSTOP_OS_PRODUCT_TYPE]: string} = {
  [PASSTOP_OS_PRODUCT_TYPE.S]: 'Passtop O.S+',
  [PASSTOP_OS_PRODUCT_TYPE.S1]: 'Passtop O.S1',
  [PASSTOP_OS_PRODUCT_TYPE.S2]: 'Passtop O.S2',
  [PASSTOP_OS_PRODUCT_TYPE.S3]: 'Passtop O.S3',
};

export const passtopOsRules: {[key in PASSTOP_OS_PRODUCT_TYPE]: string} = {
  [PASSTOP_OS_PRODUCT_TYPE.S]: '/EMB_EPR_EBT_passtopOS4:/',
  [PASSTOP_OS_PRODUCT_TYPE.S1]: '/EMB_EPR_EBT_passtopOS1:/',
  [PASSTOP_OS_PRODUCT_TYPE.S2]: '/EMB_EPR_EBT_passtopOS2:/',
  [PASSTOP_OS_PRODUCT_TYPE.S3]: '/EMB_EPR_EBT_passtopOS3:/',
};

export enum TYPE {
  PRODUCT_CATEGORY = 'category',
  DEPORTE_BRAND = 'deporteBrand',
  BRAND = 'brand',
  MATERIAL = 'material',
  COLOR = 'color',
  FILTER = 'filter',
  SURFACE = 'surface',
  EVENT = 'event',
  EVENT_TYPE_AND_SIZE = 'EVENT_TYPE_AND_SIZE',
  TUBE = 'tube',
  OPTION_TUBE = 'option_tube',
  SHOULDER = 'shoulder',
  COLOR_MARKER = 'color_marker',
  OTOSCAN = 'otoscan',
  EAR_BUD = 'ear_bud',
  EAR_BUD_ASSEMBLY = 'ear_bud_assembly',
  MISCELLANEOUS = 'miscellaneous',
  EARWAX_SHIELDING = 'earwax_shielding',
  FLOAT = 'float',
  CORD_COLOR = 'cord_color',
  OPTION = 'option',
  ENGRAVING = 'engraving',
  CAMO = 'CAMO',
  CORD = 'CORD',
  OBTURATEUR_PRODUCT_TYPE = 'OBTURATEUR_PRODUCT_TYPE',
  PASSTOP_OR_PRODUCT_TYPE = 'PASSTOP_OR_PRODUCT_TYPE',
  DEPORTE_OPTION = 'DEPORTE_OPTION',
  FINISH = 'FINISH',
  SHAPE = 'SHAPE',
  EARWAX_SHIELD = 'EARWAX_SHIELD',
  EARBACK_PRODUCT = 'EARBACK_PRODUCT',
  PASSTOP_OS_PRODUCT_TYPE = 'PASSTOP_OS_PRODUCT_TYPE',
  EARBACK_COLORATION = 'EARBACK_COLORATION',
}

export const getTypeLabel = (type: TYPE): string => {
  switch (type) {
    case TYPE.PRODUCT_CATEGORY:
      return 'Catégorie';
    case TYPE.DEPORTE_BRAND:
      return 'Marque';
    case TYPE.BRAND:
      return 'Marque';
    case TYPE.MATERIAL:
      return 'Matière';
    case TYPE.COLOR:
      return 'Couleur';
    case TYPE.FILTER:
      return 'Filtre';
    case TYPE.SURFACE:
      return 'Traitement de surface';
    case TYPE.EVENT:
      return 'Diamètre event';
    case TYPE.EVENT_TYPE_AND_SIZE:
      return 'Event (Type & Diamètre)';
    case TYPE.TUBE:
      return 'Tube';
    case TYPE.OPTION_TUBE:
      return 'Options Tube';
    case TYPE.SHOULDER:
      return 'Longueur épaulement';
    case TYPE.COLOR_MARKER:
      return 'Repère de couleur';
    case TYPE.OTOSCAN:
      return 'OTOSCAN Otometrics';
    case TYPE.EAR_BUD:
      return 'Écouteur';
    case TYPE.EAR_BUD_ASSEMBLY:
      return 'Montage Écouteur';
    case TYPE.MISCELLANEOUS:
      return 'Divers';
    case TYPE.EARWAX_SHIELDING:
      return 'Pare-cérumen';
    case TYPE.FLOAT:
      return 'Options';
    case TYPE.CORD_COLOR:
      return 'Cordon';
    case TYPE.OPTION:
    case TYPE.DEPORTE_OPTION:
      return 'Options';
    case TYPE.ENGRAVING:
      return 'Gravure';
    case TYPE.CAMO:
      return 'Option';
    case TYPE.CORD:
      return 'Cordon';
    case TYPE.PASSTOP_OR_PRODUCT_TYPE:
    case TYPE.OBTURATEUR_PRODUCT_TYPE:
      return 'Type de produit';
    case TYPE.FINISH:
      return 'Finition';
    case TYPE.SHAPE:
      return 'Forme';
    case TYPE.EARBACK_PRODUCT:
      return 'Produit';
    case TYPE.PASSTOP_OS_PRODUCT_TYPE:
      return 'Type de produit';
    case TYPE.EARBACK_COLORATION:
      return 'Coloration';
    default:
      return '%MISSING_TYPE_LABEL%';
  }
};

export type Typed = {type?: TYPE};
export type Product = CLASSIQUE_SHAPE | DEPORTE_SHAPE | PROTECTION_SHAPE;
export type Shape = DEPORTE_SHAPE;
export type Material = CLASSIQUE_MATERIAL | DEPORTE_MATERIAL;

type CommonOption = {
  [key in Material | COLOR | FILTER]?: OptionDataContainer & Typed;
};

export const CommonOptionDataKeys: string[] = ['type', 'required', 'unique'];

export const BOOLEAN_VALUE = '__BOOLEAN_VALUE';
type BOOLEAN_VALUE_TYPE = typeof BOOLEAN_VALUE;

export interface OptionValueMap<T = string> {
  value: T;
  type?: OPTION_GROUP_CHECK_TYPE;
  radioGroup?: string;
  disabledCallback?: (
    selectedOptions: OptionInterface[],
    index: number,
  ) => boolean;
  defaultSelectedCallback?: (
    selectedOptions: OptionInterface[],
    index: number,
  ) => boolean;
  alwaysSelected?: true;
  defaultSelected?: true;
  isPair?: true;
  maxLength?: number;
}

export interface OptionData<T = string> {
  required?: boolean;
  unique?: boolean;
  type: TYPE;
  values: (OptionValueMap<T> | T)[];
  valuesBrandCallback?: (brand: DEPORTE_BRAND) => T[];
}

export interface OptionDataContainer {
  options: OptionData[];
  required?: boolean;
  unique?: boolean;
}

const drilledMicrotubeDisabledCallback = (
  options: OptionInterface[],
  index: number,
): boolean =>
  !!options
    .filter(({name}) => [OPTION.DRILLED_MICROTUBE].includes(name as OPTION))
    .find(({selected}) => selected[index]);

export const materialLabels: {[key in Material]: string} = {
  [CLASSIQUE_MATERIAL.RESINE_3D]: 'Résine UV',
  [CLASSIQUE_MATERIAL.PLATINUM_25_SH]: 'Silicone 25 sh',
  [CLASSIQUE_MATERIAL.PLATINUM_40_SH]: 'Silicone 40 sh',
  [CLASSIQUE_MATERIAL.PLATINUM_60_SH]: 'Silicone 60 sh',
  [CLASSIQUE_MATERIAL.THERMOTEC]: 'Thermotec',
  [DEPORTE_MATERIAL.RESINE_3D]: 'Résine UV',
  [DEPORTE_MATERIAL.SILICON_PLATNIUM_40]: 'Silicone 40 sh',
  [DEPORTE_MATERIAL.THERMOTEC]: 'Thermotec',
};

export const shapeLabels: {[key in Shape]: string} = {
  [DEPORTE_SHAPE.DEPORTE]: 'Embouts déportés',
  [DEPORTE_SHAPE.CANULE_AERO]: 'Canule Aéro',
  [DEPORTE_SHAPE.COQUE]: 'Coque',

  [DEPORTE_SHAPE.DEPORTE_CANULE]: 'Canule',
  [DEPORTE_SHAPE.DEPORTE_CANULE_CIC]: 'Canule CIC (discret)',
  [DEPORTE_SHAPE.DEPORTE_SKELETON]: 'Squelette',
  [DEPORTE_SHAPE.DEPORTE_CRAB_PINCER]: 'Silhouette',
  [DEPORTE_SHAPE.DEPORTE_CONQUE]: 'Fond de conque',
  [DEPORTE_SHAPE.DEPORTE_HALF_CONQUE]: 'Demi-conque',

  [DEPORTE_SHAPE.AERO_CANULE]: 'Canule',
  [DEPORTE_SHAPE.AERO_SKELETON]: 'Squelette',

  [DEPORTE_SHAPE.CRAB_CANULE]: 'Canule',
};

export const brandLabels: {[key in DEPORTE_BRAND]: string} = {
  [DEPORTE_BRAND.SIEMENS]: 'Siemens',
  [DEPORTE_BRAND.OTICON]: 'Oticon',
  [DEPORTE_BRAND.BELTONE]: 'Beltone',
  [DEPORTE_BRAND.WIDEX]: 'Widex',
  [DEPORTE_BRAND.PHONAK]: 'Phonak',
  [DEPORTE_BRAND.STARKEY]: 'Starkey',
};

type DeporteShape = {
  [key in DEPORTE_SHAPE]?: CommonOption & Typed;
};
export type ProductCategoryType = (
  | DeporteShape
  | CommonOption
  | OptionDataContainer
) &
  Typed;

export const isOptionData = (type: ProductCategoryType): type is OptionData =>
  !!(type as OptionData).values;

type ProductCategory = {
  [key in Product]?: ProductCategoryType;
};

export type OptionsTree = {
  [key in PRODUCT_CATEGORY]: ProductCategory & Typed;
};

export enum EVENT {
  D00_MM = 'D00_MM',
  D05_MM = 'D05_MM',
  D08_MM = 'D08_MM',
  D10_MM = 'D10_MM',
  D12_MM = 'D12_MM',
  D13_MM = 'D13_MM',
  D15_MM = 'D15_MM',
  D20_MM = 'D20_MM',
  D25_MM = 'D25_MM',
  D30_MM = 'D30_MM',
  MAX = 'MAX',
  SPIRAL = 'SPIRAL',
  PARALLEL = 'PARALLEL',
  Y_SHAPE = 'Y_SHAPE',
  GROOVED = 'GROOVED',
  POWER_VENT = 'POWER_VENT',
  CUSTOM = 'CUSTOM',
  NONE = 'NONE',
}
export const eventLabels: {[key in EVENT]: string} = {
  [EVENT.D00_MM]: '0 mm',
  [EVENT.D05_MM]: '0,5 mm',
  [EVENT.D08_MM]: '0,8 mm',
  [EVENT.D10_MM]: '1 mm',
  [EVENT.D12_MM]: '1,2 mm',
  [EVENT.D13_MM]: '1,3 mm',
  [EVENT.D15_MM]: '1,5 mm',
  [EVENT.D20_MM]: '2 mm',
  [EVENT.D25_MM]: '2,5 mm',
  [EVENT.D30_MM]: '3 mm',
  [EVENT.MAX]: 'Max',
  [EVENT.SPIRAL]: 'Spiralé',
  [EVENT.PARALLEL]: 'Parallèle',
  [EVENT.GROOVED]: 'Rainuré',
  [EVENT.Y_SHAPE]: 'En Y',
  [EVENT.CUSTOM]: 'Event à la demande',
  [EVENT.POWER_VENT]: 'Power vent',
  [EVENT.NONE]: 'Aucun',
};

export const eventRules: {[key in EVENT]?: string} = {
  [EVENT.D05_MM]: '/EMB_RES_DBC_05:/EMB_SIL_DBC_05:/EMB_MCE_DBC_05:/',
  [EVENT.D08_MM]: '/EMB_MCE_DBC_08:/EMB_RES_DBC_08:/EMB_SIL_DBC_08:/',
  [EVENT.D10_MM]: '/EMB_MCE_DBC_10:/EMB_RES_DBC_10:/EMB_SIL_DBC_10:/',
  [EVENT.D12_MM]: '/EMB_MCE_DBC_12:/EMB_RES_DBC_12:/EMB_SIL_DBC_12:/',
  [EVENT.D15_MM]: '/EMB_MCE_DBC_15:/EMB_RES_DBC_15:/EMB_SIL_DBC_15:/',
  [EVENT.D20_MM]: '/EMB_MCE_DBC_20:/EMB_RES_DBC_20:/EMB_SIL_DBC_20:/',
  [EVENT.D25_MM]: '/EMB_MCE_DBC_25:/EMB_RES_DBC_25:/EMB_SIL_DBC_25:/',
  [EVENT.D30_MM]: '/EMB_MCE_DBC_30:/EMB_RES_DBC_30:/EMB_SIL_DBC_30:/',
  [EVENT.MAX]: '/EMB_MCE_DBC_MX:/EMB_RES_DBC_MX:/EMB_SIL_DBC_MX:/',

  [EVENT.PARALLEL]:
    '/EMB_MCE_DBC_Event_paralelle:/EMB_RES_DBC_Event_paralelle:/EMB_SIL_DBC_Event_paralelle:/',
  [EVENT.SPIRAL]:
    '/EMB_MCE_DBC_Event_spirale:/EMB_RES_DBC_Event_spirale:/EMB_SIL_DBC_Event_spirale:/',
  [EVENT.GROOVED]:
    '/EMB_MCE_DBC_Event_rainure:/EMB_RES_DBC_Event_rainure:/EMB_SIL_DBC_Event_rainure:/',
  [EVENT.Y_SHAPE]:
    '/EMB_MCE_DBC_Event_y:/EMB_RES_DBC_Event_y:/EMB_SIL_DBC_Event_y:/',
  [EVENT.POWER_VENT]: '/EMB_MCE_DBC_Event_power:/EMB_RES_DBC_Event_power:/',
};

export enum TUBE {
  D25_MM = 'D25_MM',
  D30_MM = 'D30_MM',
  D33_MM = 'D33_MM',
  WATERPROOF = 'WATERPROOF',

  FLEXIBLE = 'FLEXIBLE',
  MAT = 'MAT',
  COLORED = 'COLORED',
  HYDROSTOP = 'HYDROSTOP',
  IA = 'IA',
  SQUARE_SHOULDER = 'SQUARE_SHOULDER',
  CHILD = 'CHILD',
  LIBBY_3 = 'LIBBY_3',
  LIBBY_4 = 'LIBBY_4',
  LOCK = 'LOCK',

  DRILLED_MICROTUBE = 'DRILLED_MICROTUBE',
}
export const tubeLabels: {[key in TUBE]: string} = {
  [TUBE.D25_MM]: 'Tube diamètre 2,5mm',
  [TUBE.D30_MM]: 'Tube diamètre 3 mm (standard)',
  [TUBE.D33_MM]: 'Tube diamètre 3,3 mm',
  [TUBE.WATERPROOF]: 'Tube anti-humidité',

  [TUBE.FLEXIBLE]: 'Standard',
  [TUBE.MAT]: 'Mat',
  [TUBE.COLORED]: 'Coloré : Chair/marron',
  [TUBE.HYDROSTOP]: 'Hydrostop',
  [TUBE.IA]: 'Épais',
  [TUBE.SQUARE_SHOULDER]: 'Coude équerre',
  [TUBE.CHILD]: 'Enfant',
  [TUBE.LIBBY_3]: 'Libby 3',
  [TUBE.LIBBY_4]: 'Libby 4',
  [TUBE.LOCK]: 'Lock',

  [TUBE.DRILLED_MICROTUBE]: 'Perçage micro-tube',
};

export const tubeRules: {[key in TUBE]: string} = {
  [TUBE.D25_MM]: 'TUB_tube25',
  [TUBE.D30_MM]: 'TUB_tube30',
  [TUBE.D33_MM]: 'TUB_tube33',
  [TUBE.WATERPROOF]: 'TUB_humide',

  [TUBE.FLEXIBLE]: '/EMB_MCE_autre:/EMB_SIL_TUB_souple:/EMB_RES_TUB_souple:/',
  [TUBE.MAT]: '/EMB_SIL_TUB_tam:/EMB_RES_TUB_tam:/EMB_RES_TUB_mat:/',
  [TUBE.COLORED]: '/EMB_SIL_TUP_colore:/EMB_RES_TUB_colore:/',
  [TUBE.HYDROSTOP]:
    '/EMB_MCE_autre:/EMB_RES_TUB_hydrostop:/EMB_SIL_TUB_hydrostop:/',
  [TUBE.IA]: '/EMB_SIL_TUB_iaGrosDiam:/EMB_RES_TUB_iaGrosDiam:/',
  [TUBE.SQUARE_SHOULDER]:
    '/EMB_SIL_TUB_coudeEquerre:/EMB_RES_TUB_coudeEquerre:/',
  [TUBE.CHILD]: '/EMB_SIL_TUB_enfant:/EMB_RES_TUB_enfant:/',
  [TUBE.LIBBY_3]: '/EMB_RES_TUB_libbyTrois:/EMB_SIL_TUB_libbyTrois:/',
  [TUBE.LIBBY_4]: '/EMB_SIL_TUB_libbyQuatre:/EMB_RES_TUB_libbyQuatre:/',
  [TUBE.LOCK]: '/EMB_SIL_TUB_tubeLock:/EMB_RES_autre:/EMB_RES_TUB_tubeLock:/',

  [TUBE.DRILLED_MICROTUBE]: '/EMB_RES_TUB_percage:/',
};

export enum OPTION_TUBE {
  GUIDE = 'GUIDE',
  RIGHT_ANGLE = 'RIGHT_ANGLE',
  LOCK = 'LOCK',
}

export const optionTubeLabels: {[key in OPTION_TUBE]: string} = {
  [OPTION_TUBE.GUIDE]: 'Guide tube',
  [OPTION_TUBE.RIGHT_ANGLE]: 'Coude équerre angle droit',
  [OPTION_TUBE.LOCK]: 'Bague lock',
};
export const optionTubeRules: {[key in OPTION_TUBE]: string} = {
  [OPTION_TUBE.GUIDE]: 'OPT_guideTube',
  [OPTION_TUBE.RIGHT_ANGLE]: 'OPT_coudeEq',
  [OPTION_TUBE.LOCK]: 'OPT_bague',
};

export const TubeOptions: OptionData<TUBE> = {
  ...unique,
  type: TYPE.TUBE,
  values: [TUBE.D25_MM, TUBE.D30_MM, TUBE.D33_MM, TUBE.WATERPROOF],
};

export const OptionTubeOptions: OptionData<OPTION_TUBE> = {
  type: TYPE.OPTION_TUBE,
  values: [OPTION_TUBE.GUIDE, OPTION_TUBE.RIGHT_ANGLE, OPTION_TUBE.LOCK],
};

export enum SHOULDER {
  LONG = 'LONG',
  SHORT = 'SHORT',
}
export const shoulderLabels: {[key in SHOULDER]: string} = {
  [SHOULDER.LONG]: 'Épaulement long',
  [SHOULDER.SHORT]: 'Épaulement court',
};

export const ShoulderOptions: OptionData<SHOULDER> = {
  ...unique,
  type: TYPE.SHOULDER,
  values: [SHOULDER.LONG, SHOULDER.SHORT],
};

export const ColorMarkerOptions: OptionData<BOOLEAN_VALUE_TYPE> = {
  ...unique,
  type: TYPE.COLOR_MARKER,
  values: [BOOLEAN_VALUE],
};

export const OtoscanOptions: OptionData<BOOLEAN_VALUE_TYPE> = {
  ...unique,
  type: TYPE.OTOSCAN,
  values: [BOOLEAN_VALUE],
};

export enum EAR_BUD {
  S = 'S',
  M = 'M',
  P = 'P',
  /* SP = 'SP',
  MICRO = 'MICRO',
  SILK = 'SILK', */

  S_3 = 'S_3',
  M_3 = 'M_3',
  P_3 = 'P_3',
  MICRO_EASY = 'MICRO_EASY',
  MICRO_MOMENT = 'MICRO_MOMENT',
  MICRO = 'MICRO',
  MICRO_2 = 'MICRO_2',
  MICRO_3 = 'MICRO_3',

  STANDARD = 'STANDARD',
  MEDIUM = 'MEDIUM',
  INTIGA = 'INTIGA',
  ALTA_60 = 'ALTA_60',
  ALTA_85 = 'ALTA_85',
  ALTA_100 = 'ALTA_100',
  RIC80 = 'RIC80',
  /* CORDA_SQUARED = 'CORDA_SQUARED',
  MINI_FIT_CORDA = 'MINI_FIT_CORDA', */

  LP_40DB = 'LP_40DB',
  MP_50DB = 'MP_50DB',
  HP_60DB = 'HP_60DB',

  C_LP_40DB = 'C_LP_40DB',
  C_MP_50DB = 'C_MP_50DB',
  C_HP_60DB = 'C_HP_60DB',

  SUREFIT_3_LP_40DB = 'SUREFIT_3_LP_40DB',
  SUREFIT_3_MP_50DB = 'SUREFIT_3_MP_50DB',
  SUREFIT_3_HP_60DB = 'SUREFIT_3_HP_60DB',
  SUREFIT_3_MRIE_MP = 'SUREFIT_3_MRIE_MP',

  BELTONE_S = 'BELTONE_S',
  HP2 = 'HP2',
  HPG = 'HPG',
  LP_NP = 'LP_NP',

  EASYWEAR_S = 'EASYWEAR_S',
  EASYWEAR_M = 'EASYWEAR_M',
  EASYWEAR_P = 'EASYWEAR_P',

  MOMENT_M = 'MOMENT_M',
  MOMENT_P = 'MOMENT_P',
  /* MICRO_TUBE_ELAN_13 = 'MICRO_TUBE_ELAN_13',
  MICRO_TUBE_M_10 = 'MICRO_TUBE_M_10',
  MICRO_TUBE_EASYWEAR_09_16 = 'MICRO_TUBE_EASYWEAR_09_16', */

  ECOUTEUR_XS = 'ECOUTEUR_XS',
  ECOUTEUR_XP = 'ECOUTEUR_XP',
  /* M_TUBE = 'M_TUBE', */
  ECOUTEUR_MARVEL_S = 'ECOUTEUR_MARVEL_S',
  ECOUTEUR_MARVEL_M = 'ECOUTEUR_MARVEL_M',
  ECOUTEUR_MARVEL_P = 'ECOUTEUR_MARVEL_P',
  STANDARD_45DB = 'STANDARD_45DB',
  POWER = 'POWER',
  /* CROS = 'CROS', */

  MINIFIT_DETECT_60 = 'MINIFIT_DETECT_60',
  MINIFIT_DETECT_85 = 'MINIFIT_DETECT_85',
  MINIFIT_DETECT_100 = 'MINIFIT_DETECT_100',

  DB_40 = 'DB_40',
  DB_50 = 'DB_50',
  DB_60 = 'DB_60',

  DRILLED_MICROTUBE = 'DRILLED_MICROTUBE',
}

export const earBudLabels: {[key in EAR_BUD]: string} = {
  [EAR_BUD.S]: 'S 2.0',
  [EAR_BUD.M]: 'M 2.0',
  [EAR_BUD.P]: 'P 2.0',

  [EAR_BUD.S_3]: 'S 3.0',
  [EAR_BUD.M_3]: 'M 3.0',
  [EAR_BUD.P_3]: 'P 3.0',
  [EAR_BUD.MICRO]: 'µ tube',
  [EAR_BUD.MICRO_EASY]: 'µ tube Easywear',
  [EAR_BUD.MICRO_MOMENT]: 'µ tube Moment',
  [EAR_BUD.MICRO_2]: 'µ tube 2.0',
  [EAR_BUD.MICRO_3]: 'µ tube 3.0',

  [EAR_BUD.DRILLED_MICROTUBE]: 'Perçage µ tube',

  [EAR_BUD.STANDARD]: 'Standard',
  [EAR_BUD.MEDIUM]: 'Medium',
  [EAR_BUD.INTIGA]: 'Intiga',
  [EAR_BUD.ALTA_60]: 'Alta 60',
  [EAR_BUD.ALTA_85]: 'Alta 85',
  [EAR_BUD.ALTA_100]: 'Alta 100',
  [EAR_BUD.RIC80]: 'Design RIC80',

  [EAR_BUD.LP_40DB]: 'Surefit 2 LP - 40dB',
  [EAR_BUD.MP_50DB]: 'Surefit 2 MP - 50dB',
  [EAR_BUD.HP_60DB]: 'Surefit 2 HP - 60dB',

  [EAR_BUD.C_LP_40DB]: 'Surefit 2 C LP - 40dB',
  [EAR_BUD.C_MP_50DB]: 'Surefit 2 C MP - 50dB',
  [EAR_BUD.C_HP_60DB]: 'Surefit 2 C HP - 60dB',

  [EAR_BUD.SUREFIT_3_LP_40DB]: 'Surefit 3 LP - 40dB',
  [EAR_BUD.SUREFIT_3_MP_50DB]: 'Surefit 3 MP - 50dB',
  [EAR_BUD.SUREFIT_3_HP_60DB]: 'Surefit 3 HP - 60dB',
  [EAR_BUD.SUREFIT_3_MRIE_MP]: 'Surefit 3 M&RIE - MP',

  [EAR_BUD.BELTONE_S]: 'S',
  [EAR_BUD.HP2]: 'HP2',
  [EAR_BUD.HPG]: 'HPG ancienne génération',
  [EAR_BUD.LP_NP]: 'LP/NP ancienne génération',

  [EAR_BUD.EASYWEAR_S]: 'Easywear S',
  [EAR_BUD.EASYWEAR_M]: 'Easywear M',
  [EAR_BUD.EASYWEAR_P]: 'Easywear P',

  [EAR_BUD.MOMENT_M]: 'Moment M',
  [EAR_BUD.MOMENT_P]: 'Moment P',

  [EAR_BUD.ECOUTEUR_XS]: 'Ecouteur XS',
  [EAR_BUD.ECOUTEUR_XP]: 'Ecouteur XP',
  [EAR_BUD.ECOUTEUR_MARVEL_S]: 'Ecouteur Marvel S',
  [EAR_BUD.ECOUTEUR_MARVEL_M]: 'Ecouteur Marvel M',
  [EAR_BUD.ECOUTEUR_MARVEL_P]: 'Ecouteur Marvel P',
  [EAR_BUD.STANDARD_45DB]: 'Standard 45dB ancienne génération',
  [EAR_BUD.POWER]: 'Power ancienne génération',

  [EAR_BUD.MINIFIT_DETECT_60]: 'Minifit Detect 60',
  [EAR_BUD.MINIFIT_DETECT_85]: 'Minifit Detect 85',
  [EAR_BUD.MINIFIT_DETECT_100]: 'Minifit Detect 100',

  [EAR_BUD.DB_40]: 'Ecouteur 40 dB',
  [EAR_BUD.DB_50]: 'Ecouteur 50 dB',
  [EAR_BUD.DB_60]: 'Ecouteur 60 dB',
};

export const earBudRules: {[key in EAR_BUD]?: string} = {};

export const SiemensEarBudOptions: EAR_BUD[] = [
  EAR_BUD.S,
  EAR_BUD.M,
  EAR_BUD.P,

  EAR_BUD.S_3,
  EAR_BUD.M_3,
  EAR_BUD.P_3,
  EAR_BUD.MICRO_3,

  EAR_BUD.MICRO_2,
];

export const OticonEarBudOptions: EAR_BUD[] = [
  EAR_BUD.STANDARD,
  EAR_BUD.MEDIUM,
  EAR_BUD.INTIGA,
  EAR_BUD.ALTA_60,
  EAR_BUD.ALTA_85,
  EAR_BUD.ALTA_100,
  EAR_BUD.RIC80,
  EAR_BUD.MICRO,
  EAR_BUD.MINIFIT_DETECT_60,
  EAR_BUD.MINIFIT_DETECT_85,
  EAR_BUD.MINIFIT_DETECT_100,
];

export const BeltoneEarBudOptions: EAR_BUD[] = [
  EAR_BUD.LP_40DB,
  EAR_BUD.MP_50DB,
  EAR_BUD.HP_60DB,
  EAR_BUD.C_LP_40DB,
  EAR_BUD.C_MP_50DB,
  EAR_BUD.C_HP_60DB,
  EAR_BUD.BELTONE_S,
  EAR_BUD.SUREFIT_3_LP_40DB,
  EAR_BUD.SUREFIT_3_MP_50DB,
  EAR_BUD.SUREFIT_3_HP_60DB,
  EAR_BUD.SUREFIT_3_MRIE_MP,
  EAR_BUD.HP2,
  EAR_BUD.HPG,
  EAR_BUD.LP_NP,

  EAR_BUD.MICRO,
];

export const WidexEarBudOptions: EAR_BUD[] = [
  EAR_BUD.MOMENT_M,
  EAR_BUD.MOMENT_P,

  EAR_BUD.EASYWEAR_S,
  EAR_BUD.EASYWEAR_M,
  EAR_BUD.EASYWEAR_P,

  EAR_BUD.MICRO_EASY,
  EAR_BUD.MICRO_MOMENT,
];

export const PhonakEarBudOptions: EAR_BUD[] = [
  EAR_BUD.ECOUTEUR_XS,
  EAR_BUD.ECOUTEUR_XP,
  EAR_BUD.ECOUTEUR_MARVEL_S,
  EAR_BUD.ECOUTEUR_MARVEL_M,
  EAR_BUD.ECOUTEUR_MARVEL_P,
  EAR_BUD.STANDARD_45DB,
  EAR_BUD.POWER,

  EAR_BUD.MICRO,
];

export const StarkeyEarBudOptions: EAR_BUD[] = [
  EAR_BUD.DB_40,
  EAR_BUD.DB_50,
  EAR_BUD.DB_60,

  EAR_BUD.MICRO,
];

export const brandEarBudMap: {[brand in DEPORTE_BRAND]: EAR_BUD[]} = {
  [DEPORTE_BRAND.SIEMENS]: SiemensEarBudOptions,
  [DEPORTE_BRAND.OTICON]: OticonEarBudOptions,
  [DEPORTE_BRAND.BELTONE]: BeltoneEarBudOptions,
  [DEPORTE_BRAND.WIDEX]: WidexEarBudOptions,
  [DEPORTE_BRAND.PHONAK]: PhonakEarBudOptions,
  [DEPORTE_BRAND.STARKEY]: StarkeyEarBudOptions,
};

export enum EARWAX_SHIELD {
  SIGNIA_RING = 'SIGNIA_RING',
  VALVE = 'VALVE',
  LONG_SIGNIA = 'LONG_SIGNIA',

  ASSEMBLED = 'ASSEMBLED',
  MARVEL_RING = 'MARVEL_RING',
  LONG_PHONAK = 'LONG_PHONAK',

  ORIGINAL = 'ORIGINAL',

  LOCKED = 'LOCKED',
}

export const earWaxShieldLabels: {[key in EARWAX_SHIELD]: string} = {
  [EARWAX_SHIELD.SIGNIA_RING]: 'Bague Signia + pare cérumen HF4',
  [EARWAX_SHIELD.VALVE]: 'Pare cérumen à clapet',
  [EARWAX_SHIELD.LONG_SIGNIA]: 'Version longue Signia (Bague + C-grid)',
  [EARWAX_SHIELD.ASSEMBLED]: 'Montage tube',
  [EARWAX_SHIELD.MARVEL_RING]: 'Bague Phonak Marvel',
  [EARWAX_SHIELD.LONG_PHONAK]:
    'Version longue Phonak (Plasting Bushing + Cérustop)',
  [EARWAX_SHIELD.ORIGINAL]: 'Bague',
  [EARWAX_SHIELD.LOCKED]: 'Bague vérouil. Widex',
};

export const earWaxShieldRules: {[key in EARWAX_SHIELD]: string} = {
  [EARWAX_SHIELD.SIGNIA_RING]: '/EMB_MCE_PAR_signiaHF:/',
  [EARWAX_SHIELD.VALVE]: '/EMB_MCE_PAR_clapet:/EMB_RES_autre:/',
  [EARWAX_SHIELD.LONG_SIGNIA]: '/EMB_MCE_PAR_signia:/',
  [EARWAX_SHIELD.ASSEMBLED]: '/EMB_MCE_PAR_tube:/',
  [EARWAX_SHIELD.MARVEL_RING]: '/EMB_MCE_PAR_phonak:/',
  [EARWAX_SHIELD.LONG_PHONAK]: '/EMB_MCE_PAR_longuePhonak:/',
  [EARWAX_SHIELD.ORIGINAL]: '/EMB_MCE_PAR_origine:/',
  [EARWAX_SHIELD.LOCKED]: '/EMB_MCE_PAR_widex:/',
};

const siliconSkelettonDisabledCallback = (
  options: OptionInterface[],
  index: number,
): boolean =>
  !!options
    .filter(({name}) =>
      [DEPORTE_SHAPE.DEPORTE_SKELETON].includes(name as DEPORTE_SHAPE),
    )
    .find(({selected}) => selected[index]) ||
  siliconDisabledCallback(options, index);

const siliconDisabledCallback = (
  options: OptionInterface[],
  index: number,
): boolean =>
  !!options
    .filter(({name}) =>
      [DEPORTE_MATERIAL.SILICON_PLATNIUM_40].includes(name as DEPORTE_MATERIAL),
    )
    .find(({selected}) => selected[index]);

const getSiemensDisabledCallback = (shield: EARWAX_SHIELD) => (
  options: OptionInterface[],
  index: number,
): boolean => {
  const hasTube3 = !!options
    .filter(({name}) =>
      [EAR_BUD.P_3, EAR_BUD.M_3, EAR_BUD.S_3, EAR_BUD.MICRO_3].includes(
        name as EAR_BUD,
      ),
    )
    .find(({selected}) => selected[index]);

  if (
    hasTube3 &&
    [
      EARWAX_SHIELD.SIGNIA_RING,
      EARWAX_SHIELD.VALVE,
      EARWAX_SHIELD.LONG_SIGNIA,
    ].includes(shield)
  ) {
    return true;
  }

  const hasTube1 = !!options
    .filter(({name}) =>
      [EAR_BUD.P, EAR_BUD.M, EAR_BUD.S].includes(name as EAR_BUD),
    )
    .find(({selected}) => selected[index]);

  if (hasTube1 && [EARWAX_SHIELD.ORIGINAL].includes(shield)) {
    return true;
  }

  return siliconDisabledCallback(options, index);
};

export const SiemensEarWaxShieldOptions: OptionValueMap<EARWAX_SHIELD>[] = [
  EARWAX_SHIELD.ORIGINAL,
  EARWAX_SHIELD.VALVE,
  EARWAX_SHIELD.LONG_SIGNIA,
].map((value) => ({value, disabledCallback: siliconDisabledCallback}));

export const OticonEarWaxShieldOptions: OptionValueMap<EARWAX_SHIELD>[] = [
  EARWAX_SHIELD.ASSEMBLED,
  EARWAX_SHIELD.ORIGINAL,
].map((value) => ({value, disabledCallback: siliconDisabledCallback}));

export const BeltoneEarWaxShieldOptions: OptionValueMap<EARWAX_SHIELD>[] = [
  EARWAX_SHIELD.ASSEMBLED,
  EARWAX_SHIELD.ORIGINAL,
].map((value) => ({value, disabledCallback: siliconDisabledCallback}));

export const WidexEarWaxShieldOptions: OptionValueMap<EARWAX_SHIELD>[] = [
  EARWAX_SHIELD.ASSEMBLED,
  EARWAX_SHIELD.LOCKED,
].map((value) => ({value, disabledCallback: siliconDisabledCallback}));

export const PhonakEarWaxShieldOptions: OptionValueMap<EARWAX_SHIELD>[] = [
  {
    value: EARWAX_SHIELD.ASSEMBLED,
    defaultSelectedCallback: (options, index) => {
      if (siliconDisabledCallback(options, index)) return false;

      return !!options
        .filter(({name}) =>
          [
            EAR_BUD.ECOUTEUR_XS,
            EAR_BUD.ECOUTEUR_XP,
            EAR_BUD.STANDARD_45DB,
            EAR_BUD.POWER,
          ].includes(name as EAR_BUD),
        )
        .find(({selected}) => selected[index]);
    },
    disabledCallback: siliconDisabledCallback,
  },
  ...[EARWAX_SHIELD.ORIGINAL, EARWAX_SHIELD.LONG_PHONAK].map<
    OptionValueMap<EARWAX_SHIELD>
  >((value) => ({
    value,
    disabledCallback: siliconDisabledCallback,
  })),
];

export const StarkeyEarWaxShieldOptions: OptionValueMap<EARWAX_SHIELD>[] = [
  EARWAX_SHIELD.ASSEMBLED,
].map((value) => ({value, disabledCallback: siliconDisabledCallback}));

export const brandEarWaxShieldMap: {
  [brand in DEPORTE_BRAND]: (EARWAX_SHIELD | OptionValueMap<EARWAX_SHIELD>)[];
} = {
  [DEPORTE_BRAND.SIEMENS]: SiemensEarWaxShieldOptions,
  [DEPORTE_BRAND.OTICON]: OticonEarWaxShieldOptions,
  [DEPORTE_BRAND.BELTONE]: BeltoneEarWaxShieldOptions,
  [DEPORTE_BRAND.WIDEX]: WidexEarWaxShieldOptions,
  [DEPORTE_BRAND.PHONAK]: PhonakEarWaxShieldOptions,
  [DEPORTE_BRAND.STARKEY]: StarkeyEarWaxShieldOptions,
};

export enum MISCELLANEOUS {
  COLOR_MARKER = 'COLOR_MARKER',
  EXTRACTION_CORDS = 'EXTRACTION_CORDS',
  GROOVED_EVENT = 'GROOVED_EVENT',
  ANTIBACTERIAL_VARNISH = 'ANTIBACTERIAL_VARNISH',
  SANDING = 'SANDING',
}
export const miscellaneousLabels: {[key in MISCELLANEOUS]: string} = {
  [MISCELLANEOUS.COLOR_MARKER]: 'Repère de couleur',
  [MISCELLANEOUS.EXTRACTION_CORDS]: "Fils d'extraction",
  [MISCELLANEOUS.GROOVED_EVENT]: 'Event rainuré',
  [MISCELLANEOUS.ANTIBACTERIAL_VARNISH]: 'Vernis antibactérien',
  [MISCELLANEOUS.SANDING]: 'Sablage',
};
export const miscellaneousRules: {[key in MISCELLANEOUS]?: string} = {
  [MISCELLANEOUS.EXTRACTION_CORDS]: 'OPT_fil',
  [MISCELLANEOUS.GROOVED_EVENT]: commonRules.groovedBlowhole,
  [MISCELLANEOUS.ANTIBACTERIAL_VARNISH]: commonRules.antibacterialVarnish,
};

export const MiscellaneousOptions: OptionData<MISCELLANEOUS> = {
  type: TYPE.MISCELLANEOUS,
  values: [
    MISCELLANEOUS.COLOR_MARKER,
    MISCELLANEOUS.EXTRACTION_CORDS,
    MISCELLANEOUS.GROOVED_EVENT,
  ],
};

export const EarBudAssemblyOptions: OptionData<BOOLEAN_VALUE_TYPE> = {
  ...unique,
  type: TYPE.EAR_BUD_ASSEMBLY,
  values: [BOOLEAN_VALUE],
};

export enum OPTION {
  CLIP = 'CLIP',
  HYGIENE = 'HYGIENE',

  CORD = 'CORD',
  EAR_BUD_WHITE = 'EAR_BUD_WHITE',
  EAR_BUD_BLACK = 'EAR_BUD_BLACK',
  EAR_BUD_SWITCH = 'EAR_BUD_SWITCH',

  MAGNETIC = 'MAGNETIC',
  COLOR_BLUE = 'COLOR_BLUE',
  HANDLE = 'HANDLE',

  LINK_CORD = 'LINK_CORD',
  PINK_SPARKLES = 'PINK_SPARKLES',
  BLUE_SPARKLES = 'BLUE_SPARKLES',

  SPARKLES = 'SPARKLES',

  CONQUE = 'CONQUE',
  METAL_DETECTION = 'METAL_DETECTION',

  CLOTHES_PIN = 'CLOTHES_PIN',

  IROS = 'IROS',
  HALF_IROS = 'HALF_IROS',
  TUBE_GUIDE = 'TUBE_GUIDE',
  COMPRESSIBLE_CONDUIT = 'COMPRESSIBLE_CONDUIT',
  EXTRACTION_CORD = 'EXTRACTION_CORD',
  DRILLED_MICROTUBE = 'DRILLED_MICROTUBE',

  FLOAT = 'FLOAT',
  CORD_MARK = 'CORD_MARK',

  LASER_MARK = 'LASER_MARK',
  LOGO = 'LOGO',
  COLOR_BLUE_OS = 'COLOR_BLUE_OS',

  WITHOUT_HANDLE = 'WITHOUT_HANDLE',

  UNGLUED = 'UNGLUED',
}

export const optionLabels: {[key in OPTION]: string} = {
  [OPTION.CLIP]: 'Clip de sécurité',
  [OPTION.HYGIENE]: 'Kit hygiène',

  [OPTION.CORD]: 'Cordon',
  [OPTION.EAR_BUD_WHITE]: 'Ecouteur (coloris blanc)',
  [OPTION.EAR_BUD_BLACK]: 'Ecouteur (coloris noir)',
  [OPTION.EAR_BUD_SWITCH]: 'Ecouteur Switch (main libre)',

  [OPTION.MAGNETIC]: 'Détection magnétique',
  [OPTION.COLOR_BLUE]: 'Couleur bleu alimentaire',
  [OPTION.HANDLE]: 'Poignée de préhension',

  [OPTION.LINK_CORD]: 'Cordon de liaison',
  [OPTION.PINK_SPARKLES]: 'Paillettes roses',
  [OPTION.BLUE_SPARKLES]: 'Paillettes bleues',

  [OPTION.SPARKLES]: 'Paillettes',

  [OPTION.CONQUE]: 'Conque',
  [OPTION.METAL_DETECTION]: 'Détection métallique',

  [OPTION.CLOTHES_PIN]: 'Pince vêtement',

  [OPTION.IROS]: 'Iros',
  [OPTION.HALF_IROS]: 'Demi-Iros',
  [OPTION.TUBE_GUIDE]: 'Guide tube',
  [OPTION.COMPRESSIBLE_CONDUIT]: 'Conduit Compressible',
  [OPTION.EXTRACTION_CORD]: "Fil d'extraction",
  [OPTION.DRILLED_MICROTUBE]: 'Perçage micro-tube',

  [OPTION.FLOAT]: 'Flotteur (indisponible)',
  [OPTION.CORD_MARK]: 'Gravage sur plaque de cordon',

  [OPTION.LASER_MARK]: 'Marquage texte au laser',
  [OPTION.LOGO]: 'Insertion logo sur face place',

  [OPTION.COLOR_BLUE_OS]: 'Couleur bleu alimentaire',

  [OPTION.WITHOUT_HANDLE]: 'Sans poignée (sans cordon)',
  [OPTION.UNGLUED]: 'Tube non collé',
};

export const optionRules: {[key in OPTION]?: string} = {
  [OPTION.CLIP]: 'OPT_option07',
  [OPTION.HYGIENE]: 'OPT_option08',

  [OPTION.MAGNETIC]: 'OPT_option13',
  [OPTION.COLOR_BLUE]: 'OPT_option14',
  [OPTION.HANDLE]: 'OPT_option15',

  [OPTION.SPARKLES]: 'OPT_option16',
  [OPTION.PINK_SPARKLES]: 'OPT_option16',
  [OPTION.BLUE_SPARKLES]: 'OPT_option16',
  [OPTION.LINK_CORD]: 'OPT_aquastopC',

  [OPTION.CORD]:
    '/EMB_ART_paire_force:/EMB_EPR_OPT_Cordon:/EMB_EPR_EBT_autre:/EMB_EPR_OPT_CordonS:/',
  [OPTION.CLOTHES_PIN]: '/EMB_ART_paire_force:/EMB_EPR_OPT_pince:/',

  [OPTION.CONQUE]: '/EMB_EPR_OPR_Conque:/',
  [OPTION.METAL_DETECTION]: '/EMB_EPR_OPT_Detection:/EMB_EPR_EBT_autre:/',

  [OPTION.IROS]: '/EMB_MCE_autre:/EMB_SIL_OPT_iros:/EMB_RES_OPT_iros:/',
  [OPTION.HALF_IROS]: '/EMB_SIL_OPT_demiIros:/EMB_RES_OPT_demiIros:/',
  [OPTION.COMPRESSIBLE_CONDUIT]:
    '/EMB_SIL_OPT_conduitComp:/EMB_RES_OPT_conduitComp:/',
  // TODO comment color
  [OPTION.TUBE_GUIDE]: '/EMB_SIL_OPT_guideTube:/EMB_RES_OPT_guideTube:/',
  [OPTION.EXTRACTION_CORD]: '/EMB_RES_OPT_fil:/EMB_RES_autre:/',

  [OPTION.FLOAT]: '/EMB_ART_paire_force:/EMB_EPR_OPT_aquasonFlotteur:/',
  [OPTION.CORD_MARK]: '/EMB_ART_paire_force:/EMB_EPR_OPT_GravageCrd:/',

  [OPTION.LASER_MARK]: '/EMB_ATR_autre:/EMB_ATR_OPR_marquage:/',
  [OPTION.LOGO]: '/EMB_ATR_autre:/EMB_ATR_OPV_logo:/',

  [OPTION.COLOR_BLUE_OS]: 'OPT_Couleur',

  [OPTION.WITHOUT_HANDLE]: '/EMB_ART_paire_force:/EMB_EPR_EBT_sansPoignee:/',
  [OPTION.EAR_BUD_WHITE]: '/EMB_ART_paire_force:/EMB_EPR_PIA_ecouteurB:/',
  [OPTION.EAR_BUD_BLACK]: '/EMB_ART_paire_force:/EMB_EPR_PIA_ecouteurN:/',

  [OPTION.UNGLUED]:
    '/EMB_RES_TUB_nonColle:/EMB_SIL_TUB_nonColle:/EMB_RES_OPT_nonColle:/',
};

export const optionEmboutLabelRules: {[key in OPTION]?: string} = {
  [OPTION.DRILLED_MICROTUBE]: 'percage',
  [OPTION.TUBE_GUIDE]: 'guideTube',
  [OPTION.EXTRACTION_CORD]: 'filExtraction',
  [OPTION.COMPRESSIBLE_CONDUIT]: 'conduitCompressible',
  [OPTION.UNGLUED]: 'tubeNonColle',
};

export enum DEPORTE_OPTION {
  SHORT_EPAULEMENT = 'SHORT_EPAULEMENT',
  QUARTER_EPAULEMENT = 'QUARTER_EPAULEMENT',
  LONG_EPAULEMENT = 'LONG_EPAULEMENT',
  HALF_EPAULEMENT = 'HALF_EPAULEMENT',
  FULL = 'FULL',
  HOLLOW = 'HOLLOW',
  LEFT_RIGHT_MARK = 'LEFT_RIGHT_MARK',
  CUSTOM_MARK = 'CUSTOM_MARK',
  EXTRACTION_CORD = 'EXTRACTION_CORD',
  DRILLED_MICROTUBE = 'DRILLED_MICROTUBE',
}

export const deporteOptionLabels: {[key in DEPORTE_OPTION]: string} = {
  [DEPORTE_OPTION.SHORT_EPAULEMENT]: 'Epaulement court',
  [DEPORTE_OPTION.QUARTER_EPAULEMENT]: 'Quart épaulement',
  [DEPORTE_OPTION.LONG_EPAULEMENT]: 'Epaulement long',
  [DEPORTE_OPTION.HALF_EPAULEMENT]: 'Demi-épaulement',
  [DEPORTE_OPTION.FULL]: 'Embout plein',
  [DEPORTE_OPTION.HOLLOW]: 'Embout évidé',
  [DEPORTE_OPTION.LEFT_RIGHT_MARK]: 'Marquage Spécial',
  [DEPORTE_OPTION.CUSTOM_MARK]: 'Marquage Spécial',
  [DEPORTE_OPTION.EXTRACTION_CORD]: "Fil d'extraction",
  [DEPORTE_OPTION.DRILLED_MICROTUBE]: 'Perçage micro-tube',
};

export const deporteOptionsTypeEmbout: {[key in DEPORTE_OPTION]?: string} = {
  [DEPORTE_OPTION.FULL]: 'plein',
  [DEPORTE_OPTION.HOLLOW]: 'creux',
};

export const deporteOptionsRules: {[key in DEPORTE_OPTION]?: string} = {
  [DEPORTE_OPTION.SHORT_EPAULEMENT]:
    '/EMB_MCE_OPT_epauleCourt:/EMB_RES_autre:/',
  [DEPORTE_OPTION.QUARTER_EPAULEMENT]:
    '/EMB_MCE_OPT_epauleCourt:/EMB_RES_autre:/EMB_MCE_OPT_epauleQuart:/',
  [DEPORTE_OPTION.LONG_EPAULEMENT]:
    '/EMB_MCE_OPT_epauleLong:/EMB_RES_autre:/EMB_ATR_autre:/EMB_MCE_OPT_Epaule:/',
  [DEPORTE_OPTION.HALF_EPAULEMENT]: '/EMB_MCE_OPT_epauleDemi:/',
  // [DEPORTE_OPTION.FULL]: "label",
  // [DEPORTE_OPTION.HOLLOW]: "label",
  [DEPORTE_OPTION.LEFT_RIGHT_MARK]:
    '/EMB_MCE_OPT_marquageDG:/EMB_RES_autre:/EMB_MCE_OPT_marque:/',
  [DEPORTE_OPTION.CUSTOM_MARK]: '/EMB_MCE_OPT_marquageDG:/',
  [DEPORTE_OPTION.EXTRACTION_CORD]: '/EMB_MCE_OPT_fil:/',
  [DEPORTE_OPTION.DRILLED_MICROTUBE]: '/EMB_MCE_OPT_percage:/',
};

export const OptionOptions: OptionData<OPTION> = {
  ...unique,
  type: TYPE.OPTION,
  values: [OPTION.CLIP /*, OPTION.HYGIENE*/],
};

export const PasStopOptions: OptionData<OPTION> = {
  type: TYPE.OPTION,
  values: [OPTION.MAGNETIC, OPTION.COLOR_BLUE, OPTION.HANDLE],
};

export const ObturateurOptions: OptionData<OPTION> = {
  type: TYPE.OPTION,
  values: [OPTION.MAGNETIC, OPTION.LINK_CORD, OPTION.SPARKLES],
};

export const CamoOptions: OptionData<BOOLEAN_VALUE_TYPE> = {
  ...unique,
  type: TYPE.CAMO,
  values: [BOOLEAN_VALUE],
};

const eventSizeOptions = {
  ...requiredAndUnique,
  type: TYPE.EVENT,
  values: [
    EVENT.D05_MM,
    EVENT.D08_MM,
    EVENT.D10_MM,
    EVENT.D12_MM,
    EVENT.D15_MM,
    EVENT.D20_MM,
    EVENT.D25_MM,
    EVENT.D30_MM,
    EVENT.MAX,
    EVENT.CUSTOM,
  ],
};

const finishOptions: OptionValueMap<FINISH>[] = [
  ...[FINISH.SHINY].map<OptionValueMap<FINISH>>((value) => ({
    value,
    disabledCallback: (options, index) =>
      !options
        .filter(({name}) =>
          [DEPORTE_MATERIAL.RESINE_3D, DEPORTE_MATERIAL.THERMOTEC].includes(
            name as DEPORTE_MATERIAL,
          ),
        )
        .find(({selected}) => selected[index]),
  })),
  ...[FINISH.MATTE_SANDED, FINISH.SANDED_OUTSIDE].map<OptionValueMap<FINISH>>(
    (value) => ({
      value,
      disabledCallback: (options, index) =>
        !options
          .filter(({name}) =>
            [DEPORTE_MATERIAL.RESINE_3D].includes(name as DEPORTE_MATERIAL),
          )
          .find(({selected}) => selected[index]),
    }),
  ),
  ...[FINISH.VARNISH].map<OptionValueMap<FINISH>>((value) => ({
    value,
    disabledCallback: (options, index) =>
      !options
        .filter(({name}) =>
          [DEPORTE_MATERIAL.SILICON_PLATNIUM_40].includes(
            name as DEPORTE_MATERIAL,
          ),
        )
        .find(({selected}) => selected[index]),
  })),
  ...[FINISH.UV_VARNISH].map<OptionValueMap<FINISH>>((value) => ({
    value,
    disabledCallback: (options, index) =>
      !options
        .filter(({name}) =>
          [DEPORTE_MATERIAL.RESINE_3D].includes(name as DEPORTE_MATERIAL),
        )
        .find(({selected}) => selected[index]),
  })),
  ...[FINISH.WITHOUT_VARNISH].map<OptionValueMap<FINISH>>((value) => ({
    value,
    disabledCallback: (options, index) =>
      !options
        .filter(({name}) =>
          [DEPORTE_MATERIAL.SILICON_PLATNIUM_40].includes(
            name as DEPORTE_MATERIAL,
          ),
        )
        .find(({selected}) => selected[index]),
  })),
];

const allFinishOptions = {
  ...requiredAndUnique,
  type: TYPE.FINISH,
  values: finishOptions,
};

const someFinishOptions = {
  ...unique,
  type: TYPE.FINISH,
  values: finishOptions.filter(({value}) =>
    [FINISH.SHINY, FINISH.MATTE_SANDED, FINISH.UV_VARNISH].includes(value),
  ),
};
const getClassiqueOptions = (shape: CLASSIQUE_SHAPE): OptionData[] => [
  {
    ...requiredAndUnique,
    type: TYPE.MATERIAL,
    values: [
      CLASSIQUE_MATERIAL.RESINE_3D,
      ...(shape !== CLASSIQUE_SHAPE.PINCE_CRABE
        ? [CLASSIQUE_MATERIAL.PLATINUM_25_SH]
        : []),
      CLASSIQUE_MATERIAL.PLATINUM_40_SH,
      CLASSIQUE_MATERIAL.PLATINUM_60_SH,
      CLASSIQUE_MATERIAL.THERMOTEC,
    ],
  },
  {
    ...requiredAndUnique,
    type: TYPE.COLOR,
    values: [
      COLOR.COLORLESS_TRANSLUCENT,
      {
        value: COLOR.ROSE_TRANSLUCENT,
        disabledCallback: (options, index) =>
          !!options
            .filter(({name}) =>
              [CLASSIQUE_MATERIAL.THERMOTEC].includes(
                name as CLASSIQUE_MATERIAL,
              ),
            )
            .find(({selected}) => selected[index]),
      },
      {
        value: COLOR.BROWN,
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [
                CLASSIQUE_MATERIAL.PLATINUM_40_SH,
                CLASSIQUE_MATERIAL.RESINE_3D,
              ].includes(name as CLASSIQUE_MATERIAL),
            )
            .find(({selected}) => selected[index]),
      },
    ],
  },
  {
    ...required,
    type: TYPE.TUBE,
    values: [
      ...[
        TUBE.FLEXIBLE,
        TUBE.MAT,
        TUBE.HYDROSTOP,
        TUBE.IA,
        TUBE.SQUARE_SHOULDER,
        TUBE.CHILD,
        TUBE.LIBBY_3,
        TUBE.LIBBY_4,
      ].map((value) => ({
        value,
        radioGroup: 'tube',
        // disabledCallback: drilledMicrotubeDisabledCallback,
      })),
      {
        value: TUBE.LOCK,
        radioGroup: 'tube',
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [
                CLASSIQUE_MATERIAL.PLATINUM_25_SH,
                CLASSIQUE_MATERIAL.PLATINUM_40_SH,
                CLASSIQUE_MATERIAL.PLATINUM_60_SH,
              ].includes(name as CLASSIQUE_MATERIAL),
            )
            .find(({selected}) => selected[index]),
      },
      {
        value: TUBE.COLORED,
        radioGroup: 'tube',
        type: OPTION_GROUP_CHECK_TYPE.TUBE_COLOR_INPUT,
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [
                CLASSIQUE_MATERIAL.RESINE_3D,
                CLASSIQUE_MATERIAL.PLATINUM_25_SH,
                CLASSIQUE_MATERIAL.PLATINUM_40_SH,
                CLASSIQUE_MATERIAL.PLATINUM_60_SH,
              ].includes(name as CLASSIQUE_MATERIAL),
            )
            .find(({selected}) => selected[index]),
      },
      {
        value: OPTION.DRILLED_MICROTUBE,
        radioGroup: 'tube',
      },
    ],
  },
  {
    ...unique,
    type: TYPE.OPTION,
    values: [
      ...(shape !== CLASSIQUE_SHAPE.CANULE
        ? [
            {
              value: OPTION.IROS,
              type: OPTION_GROUP_CHECK_TYPE.RADIO,
            },
            {
              value: OPTION.HALF_IROS,
              type: OPTION_GROUP_CHECK_TYPE.RADIO,
            },
          ]
        : []),
      {
        value: OPTION.COMPRESSIBLE_CONDUIT,
        type: OPTION_GROUP_CHECK_TYPE.CHECKBOX,
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [
                CLASSIQUE_MATERIAL.PLATINUM_40_SH,
                CLASSIQUE_MATERIAL.PLATINUM_60_SH,
              ].includes(name as CLASSIQUE_MATERIAL),
            )
            .find(({selected}) => selected[index]),
      },
      {
        value: OPTION.TUBE_GUIDE,
        type: OPTION_GROUP_CHECK_TYPE.CHECKBOX,
        disabledCallback: (options, index) =>
          !!options
            .filter(({name}) =>
              [CLASSIQUE_MATERIAL.THERMOTEC].includes(
                name as CLASSIQUE_MATERIAL,
              ),
            )
            .find(({selected}) => selected[index]),
      },
      {
        value: OPTION.EXTRACTION_CORD,
        type: OPTION_GROUP_CHECK_TYPE.CHECKBOX,
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [
                CLASSIQUE_MATERIAL.RESINE_3D,
                CLASSIQUE_MATERIAL.THERMOTEC,
              ].includes(name as CLASSIQUE_MATERIAL),
            )
            .find(({selected}) => selected[index]),
      },
      {
        value: OPTION.UNGLUED,
        type: OPTION_GROUP_CHECK_TYPE.CHECKBOX,
        disabledCallback: drilledMicrotubeDisabledCallback,
      },
    ],
  },
  {
    ...requiredAndUnique,
    type: TYPE.EVENT_TYPE_AND_SIZE,
    values: [
      {
        value: EVENT.NONE,
        type: OPTION_GROUP_CHECK_TYPE.RADIO,
        radioGroup: 'type',
        defaultSelected: true,
      },
      {
        value: EVENT.PARALLEL,
        type: OPTION_GROUP_CHECK_TYPE.RADIO,
        radioGroup: 'type',
      },
      {
        value: EVENT.SPIRAL,
        type: OPTION_GROUP_CHECK_TYPE.RADIO,
        radioGroup: 'type',
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [
                CLASSIQUE_MATERIAL.RESINE_3D,
                CLASSIQUE_MATERIAL.THERMOTEC,
              ].includes(name as CLASSIQUE_MATERIAL),
            )
            .find(({selected}) => selected[index]),
      },
      ...[EVENT.GROOVED, EVENT.Y_SHAPE].map<OptionValueMap>((value) => ({
        value,
        type: OPTION_GROUP_CHECK_TYPE.RADIO,
        radioGroup: 'type',
      })),
      ...([
        CLASSIQUE_SHAPE.CANULE_EPAULEMENT,
        CLASSIQUE_SHAPE.SQUELETTE,
        CLASSIQUE_SHAPE.FOND_CONQUE,
      ].includes(shape)
        ? ([
            {
              value: EVENT.POWER_VENT,
              type: OPTION_GROUP_CHECK_TYPE.RADIO,
              radioGroup: 'type',
              disabledCallback: (options, index) =>
                !options
                  .filter(({name}) =>
                    [CLASSIQUE_MATERIAL.RESINE_3D].includes(
                      name as CLASSIQUE_MATERIAL,
                    ),
                  )
                  .find(({selected}) => selected[index]),
            },
          ] as OptionValueMap[])
        : []),
      ...[EVENT.D05_MM, EVENT.D08_MM].map<OptionValueMap>((value) => ({
        value,
        type: OPTION_GROUP_CHECK_TYPE.RADIO,
        radioGroup: 'size',
        disabledCallback: (options, index) =>
          !!options
            .filter(({name}) =>
              [EVENT.POWER_VENT, EVENT.NONE, EVENT.GROOVED].includes(
                name as EVENT,
              ),
            )
            .find(({selected}) => selected[index]),
      })),
      ...[
        EVENT.D10_MM,
        EVENT.D12_MM,
        EVENT.D15_MM,
        EVENT.D20_MM,
        EVENT.D25_MM,
        EVENT.D30_MM,
      ].map<OptionValueMap>((value) => ({
        value,
        type: OPTION_GROUP_CHECK_TYPE.RADIO,
        radioGroup: 'size',
        disabledCallback: (options, index) =>
          !!options
            .filter(({name}) => [EVENT.NONE].includes(name as EVENT))
            .find(({selected}) => selected[index]),
      })),
      ...[EVENT.MAX].map<OptionValueMap>((value) => ({
        value,
        type: OPTION_GROUP_CHECK_TYPE.RADIO,
        radioGroup: 'size',
        disabledCallback: (options, index) =>
          !!options
            .filter(({name}) =>
              [EVENT.POWER_VENT, EVENT.NONE].includes(name as EVENT),
            )
            .find(({selected}) => selected[index]),
      })),
      {
        value: EVENT.CUSTOM,
        type: OPTION_GROUP_CHECK_TYPE.EVENT_INPUT,
        radioGroup: 'size',
        disabledCallback: (options, index) =>
          !!options
            .filter(({name}) => [EVENT.NONE].includes(name as EVENT))
            .find(({selected}) => selected[index]),
      },
    ],
  },
  {
    ...requiredAndUnique,
    type: TYPE.FINISH,
    values: [
      ...[FINISH.SHINY].map<OptionValueMap>((value) => ({
        value,
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [
                CLASSIQUE_MATERIAL.RESINE_3D,
                CLASSIQUE_MATERIAL.THERMOTEC,
              ].includes(name as CLASSIQUE_MATERIAL),
            )
            .find(({selected}) => selected[index]),
      })),
      ...[FINISH.MATTE_SANDED, FINISH.SANDED_OUTSIDE].map<OptionValueMap>(
        (value) => ({
          value,
          disabledCallback: (options, index) =>
            !options
              .filter(({name}) =>
                [CLASSIQUE_MATERIAL.RESINE_3D].includes(
                  name as CLASSIQUE_MATERIAL,
                ),
              )
              .find(({selected}) => selected[index]),
        }),
      ),
      {
        value: FINISH.UV_VARNISH,
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [CLASSIQUE_MATERIAL.RESINE_3D].includes(
                name as CLASSIQUE_MATERIAL,
              ),
            )
            .find(({selected}) => selected[index]),
      },
      {
        value: FINISH.WITHOUT_VARNISH,
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [
                CLASSIQUE_MATERIAL.PLATINUM_25_SH,
                CLASSIQUE_MATERIAL.PLATINUM_40_SH,
                CLASSIQUE_MATERIAL.PLATINUM_60_SH,
              ].includes(name as CLASSIQUE_MATERIAL),
            )
            .find(({selected}) => selected[index]),
      },
      {
        value: FINISH.VARNISH,
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [
                CLASSIQUE_MATERIAL.PLATINUM_25_SH,
                CLASSIQUE_MATERIAL.PLATINUM_40_SH,
                CLASSIQUE_MATERIAL.PLATINUM_60_SH,
              ].includes(name as CLASSIQUE_MATERIAL),
            )
            .find(({selected}) => selected[index]),
      },
    ],
  },
];

const getDeporteOptions = (shape: DEPORTE_SHAPE): OptionData[] => [
  {
    ...requiredAndUnique,
    type: TYPE.SHAPE,
    values:
      shape === DEPORTE_SHAPE.DEPORTE
        ? [
            DEPORTE_SHAPE.DEPORTE_CANULE_CIC,
            DEPORTE_SHAPE.DEPORTE_CANULE,
            DEPORTE_SHAPE.DEPORTE_SKELETON,
            DEPORTE_SHAPE.DEPORTE_CONQUE,
            DEPORTE_SHAPE.DEPORTE_HALF_CONQUE,
            DEPORTE_SHAPE.DEPORTE_CRAB_PINCER,
          ]
        : shape === DEPORTE_SHAPE.CANULE_AERO
        ? [DEPORTE_SHAPE.AERO_CANULE, DEPORTE_SHAPE.AERO_SKELETON]
        : [DEPORTE_SHAPE.CRAB_CANULE],
  },
  {
    ...requiredAndUnique,
    type: TYPE.MATERIAL,
    values:
      shape === DEPORTE_SHAPE.DEPORTE
        ? [
            DEPORTE_MATERIAL.RESINE_3D,
            {
              value: DEPORTE_MATERIAL.SILICON_PLATNIUM_40,
              disabledCallback: (options, index) =>
                !!options
                  .filter(({name}) =>
                    [
                      DEPORTE_SHAPE.DEPORTE_CANULE_CIC,
                      DEPORTE_SHAPE.DEPORTE_CRAB_PINCER,
                    ].includes(name as DEPORTE_SHAPE),
                  )
                  .find(({selected}) => selected[index]),
            },
            {
              value: DEPORTE_MATERIAL.THERMOTEC,
              disabledCallback: (options, index) =>
                !!options
                  .filter(({name}) =>
                    [DEPORTE_SHAPE.DEPORTE_CANULE_CIC].includes(
                      name as DEPORTE_SHAPE,
                    ),
                  )
                  .find(({selected}) => selected[index]),
            },
          ]
        : [DEPORTE_MATERIAL.RESINE_3D],
  },
  {
    ...requiredAndUnique,
    type: TYPE.COLOR,
    values: [
      COLOR.COLORLESS_TRANSLUCENT,
      ...[COLOR.ROSE_TRANSLUCENT, COLOR.BROWN].map<OptionValueMap>((value) => ({
        value,
        disabledCallback: (options, index) =>
          !!options
            .filter(({name}) =>
              [DEPORTE_MATERIAL.THERMOTEC].includes(name as DEPORTE_MATERIAL),
            )
            .find(({selected}) => selected[index]),
      })),
    ],
  },
  ...(shape === DEPORTE_SHAPE.DEPORTE
    ? ([
        {
          ...requiredAndUnique,
          type: TYPE.EAR_BUD,
          values: [],
          valuesBrandCallback: (brand) => brandEarBudMap[brand],
        },
      ] as OptionData[])
    : []),
  {
    ...unique,
    type: TYPE.DEPORTE_OPTION,
    values: [
      ...[
        ...(shape === DEPORTE_SHAPE.DEPORTE
          ? ([
              {
                value: DEPORTE_OPTION.FULL,
                radioGroup: 'embout',
              },
              {
                value: DEPORTE_OPTION.HOLLOW,
                radioGroup: 'embout',
                disabledCallback: (options, index) =>
                  !options
                    .filter(({name}) =>
                      [DEPORTE_MATERIAL.RESINE_3D].includes(
                        name as DEPORTE_MATERIAL,
                      ),
                    )
                    .find(({selected}) => selected[index]) ||
                  !!options
                    .filter(({name}) =>
                      [EAR_BUD.S_3, EAR_BUD.M_3, EAR_BUD.P_3].includes(
                        name as EAR_BUD,
                      ),
                    )
                    .find(({selected}) => selected[index]),
              },
            ] as OptionValueMap[])
          : []),
      ],
      ...[
        DEPORTE_OPTION.LONG_EPAULEMENT,
        DEPORTE_OPTION.HALF_EPAULEMENT,
        DEPORTE_OPTION.QUARTER_EPAULEMENT,
      ].map<OptionValueMap>((value) => ({
        value,
        radioGroup: 'epaulement',
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [
                DEPORTE_SHAPE.DEPORTE_CANULE,
                DEPORTE_SHAPE.DEPORTE_CANULE_CIC,
              ].includes(name as DEPORTE_SHAPE),
            )
            .find(({selected}) => selected[index]),
      })),
      /* {
        value: DEPORTE_OPTION.LEFT_RIGHT_MARK,
        radioGroup: 'mark',
      }, */
      {
        value: DEPORTE_OPTION.CUSTOM_MARK,
        radioGroup: 'mark',
      },
      {
        value: DEPORTE_OPTION.EXTRACTION_CORD,
        radioGroup: 'cord',
        disabledCallback: (options, index) =>
          !options
            .filter(({name}) =>
              [DEPORTE_MATERIAL.RESINE_3D, DEPORTE_MATERIAL.THERMOTEC].includes(
                name as DEPORTE_MATERIAL,
              ),
            )
            .find(({selected}) => selected[index]),
      },
    ],
  },
  shape === DEPORTE_SHAPE.DEPORTE
    ? {
        ...requiredAndUnique,
        type: TYPE.EVENT_TYPE_AND_SIZE,
        values: [
          {
            value: EVENT.NONE,
            type: OPTION_GROUP_CHECK_TYPE.RADIO,
            radioGroup: 'type',
            defaultSelected: true,
          },
          ...[EVENT.PARALLEL, EVENT.GROOVED].map<OptionValueMap>((value) => ({
            value,
            type: OPTION_GROUP_CHECK_TYPE.RADIO,
            radioGroup: 'type',
          })),
          {
            value: EVENT.POWER_VENT,
            type: OPTION_GROUP_CHECK_TYPE.RADIO,
            radioGroup: 'type',
            disabledCallback: (options, index) =>
              !options
                .filter(({name}) =>
                  [
                    DEPORTE_SHAPE.DEPORTE_CANULE,
                    DEPORTE_SHAPE.DEPORTE_CANULE_CIC,
                    DEPORTE_SHAPE.DEPORTE_SKELETON,
                    DEPORTE_SHAPE.DEPORTE_CONQUE,
                  ].includes(name as DEPORTE_SHAPE),
                )
                .find(({selected}) => selected[index]) ||
              !options
                .filter(({name}) =>
                  [
                    DEPORTE_MATERIAL.RESINE_3D,
                    DEPORTE_MATERIAL.THERMOTEC,
                  ].includes(name as DEPORTE_MATERIAL),
                )
                .find(({selected}) => selected[index]),
          },
          ...[EVENT.D05_MM, EVENT.D08_MM].map<OptionValueMap>((value) => ({
            value,
            type: OPTION_GROUP_CHECK_TYPE.RADIO,
            radioGroup: 'size',
            disabledCallback: (options, index) =>
              !!options
                .filter(({name}) =>
                  [EVENT.POWER_VENT, EVENT.NONE, EVENT.GROOVED].includes(
                    name as EVENT,
                  ),
                )
                .find(({selected}) => selected[index]),
          })),
          ...[
            EVENT.D10_MM,
            EVENT.D12_MM,
            EVENT.D15_MM,
            EVENT.D20_MM,
            EVENT.D25_MM,
            EVENT.D30_MM,
          ].map<OptionValueMap>((value) => ({
            value,
            type: OPTION_GROUP_CHECK_TYPE.RADIO,
            radioGroup: 'size',
            disabledCallback: (options, index) =>
              !!options
                .filter(({name}) => [EVENT.NONE].includes(name as EVENT))
                .find(({selected}) => selected[index]),
          })),
          ...[EVENT.MAX].map<OptionValueMap>((value) => ({
            value,
            type: OPTION_GROUP_CHECK_TYPE.RADIO,
            radioGroup: 'size',
            disabledCallback: (options, index) =>
              !!options
                .filter(({name}) =>
                  [EVENT.POWER_VENT, EVENT.NONE].includes(name as EVENT),
                )
                .find(({selected}) => selected[index]),
          })),
          {
            value: EVENT.CUSTOM,
            type: OPTION_GROUP_CHECK_TYPE.EVENT_INPUT,
            radioGroup: 'size',
            disabledCallback: (options, index) =>
              !!options
                .filter(({name}) => [EVENT.NONE].includes(name as EVENT))
                .find(({selected}) => selected[index]),
          },
        ],
      }
    : eventSizeOptions,
  shape === DEPORTE_SHAPE.DEPORTE ? allFinishOptions : someFinishOptions,
  ...(shape === DEPORTE_SHAPE.DEPORTE
    ? ([
        {
          ...requiredAndUnique,
          type: TYPE.EARWAX_SHIELDING,
          values: [],
          valuesBrandCallback: (brand) => brandEarWaxShieldMap[brand],
        },
      ] as OptionData[])
    : []),
];

export const optionsTree: OptionsTree = {
  [PRODUCT_CATEGORY.CLASSIQUE]: {
    type: TYPE.BRAND,
    ...Object.fromEntries(
      [
        CLASSIQUE_SHAPE.CANULE,
        CLASSIQUE_SHAPE.CANULE_EPAULEMENT,
        CLASSIQUE_SHAPE.CANULE_DEMI_EPAULEMENT,
        CLASSIQUE_SHAPE.CANULE_QUART_EPAULEMENT,
        CLASSIQUE_SHAPE.SQUELETTE,
        CLASSIQUE_SHAPE.FOND_CONQUE,
        CLASSIQUE_SHAPE.DEMI_CONQUE,
        CLASSIQUE_SHAPE.PINCE_CRABE,
      ].map((shape) => [
        shape,
        {
          options: getClassiqueOptions(shape),
        },
      ]),
    ),
  },
  [PRODUCT_CATEGORY.DEPORTE]: {
    type: TYPE.BRAND,
    ...Object.fromEntries(
      [
        DEPORTE_SHAPE.DEPORTE,
        DEPORTE_SHAPE.CANULE_AERO,
        DEPORTE_SHAPE.COQUE,
      ].map((shape) => [
        shape,
        {
          options: getDeporteOptions(shape),
        },
      ]),
    ),
  },
  [PRODUCT_CATEGORY.PROTECTION]: {
    type: TYPE.BRAND,
    [PROTECTION_SHAPE.SOMMEIL]: {
      options: [
        {
          ...requiredAndUnique,
          type: TYPE.COLOR,
          values: [
            COLOR.TRANSLUCENT,
            COLOR.ROSE,
            COLOR.RED,
            COLOR.BLUE,
            COLOR.YELLOW,
          ],
        },
      ],
    },
    [PROTECTION_SHAPE.SWEETNIGHT_CANULE]: {
      options: [
        {
          ...requiredAndUnique,
          type: TYPE.COLOR,
          values: [
            COLOR.TRANSLUCENT,
            COLOR.ROSE,
            COLOR.RED,
            COLOR.BLUE,
            COLOR.YELLOW,
          ],
        },
      ],
    },
    [PROTECTION_SHAPE.SWEETNIGHT]: {
      options: [
        {
          ...requiredAndUnique,
          type: TYPE.COLOR,
          values: [
            COLOR.TRANSLUCENT,
            COLOR.ROSE,
            COLOR.RED,
            COLOR.BLUE,
            COLOR.YELLOW,
          ],
        },
      ],
    },
    [PROTECTION_SHAPE.AQUASTOP]: {
      options: [
        {
          ...requiredAndUnique,
          type: TYPE.COLOR,
          values: [
            COLOR.RED,
            COLOR.BLUE,
            COLOR.YELLOW,
            COLOR.GREEN,
            COLOR.ORANGE,
          ],
        },
        {
          type: TYPE.OPTION,
          values: [OPTION.CORD, OPTION.CLOTHES_PIN].map((value) => ({
            value,
            isPair: true,
          })),
        },
      ],
    },
    [PROTECTION_SHAPE.OBTURATEUR]: {
      options: [
        {
          ...requiredAndUnique,
          type: TYPE.OBTURATEUR_PRODUCT_TYPE,
          values: [
            OBTURATEUR_PRODUCT_TYPE.ANTI_NOISE,
            OBTURATEUR_PRODUCT_TYPE.ANTI_WATER,
          ],
        },
        {
          ...requiredAndUnique,
          type: TYPE.COLOR,
          values: [
            COLOR.TRANSLUCENT,
            COLOR.ROSE,
            COLOR.RED,
            COLOR.BLUE,
            COLOR.YELLOW,
            COLOR.GREEN,
            COLOR.ORANGE,
            COLOR.WHITE,
            COLOR.BLACK,
            COLOR.BROWN,
          ],
        },
        {
          type: TYPE.OPTION,
          values: [OPTION.CORD, OPTION.CLOTHES_PIN].map((value) => ({
            value,
            isPair: true,
          })),
        },
      ],
    },
    [PROTECTION_SHAPE.PASSTOP_OR]: {
      options: [
        {
          ...requiredAndUnique,
          type: TYPE.PASSTOP_OR_PRODUCT_TYPE,
          values: [
            PASSTOP_OR_PRODUCT_TYPE.R1,
            PASSTOP_OR_PRODUCT_TYPE.R2,
            PASSTOP_OR_PRODUCT_TYPE.R3,
          ],
        },
        {
          ...requiredAndUnique,
          type: TYPE.COLOR,
          values: [COLOR.COLORLESS_TRANSLUCENT, COLOR.PASSTOP_OR_BLUE],
        },
        {
          type: TYPE.OPTION,
          values: [OPTION.CONQUE, OPTION.METAL_DETECTION],
        },
      ],
    },
    [PROTECTION_SHAPE.GAME_EAR]: {
      options: [
        {
          ...requiredAndUnique,
          type: TYPE.COLOR,
          values: [
            COLOR.RED,
            COLOR.BLUE,
            COLOR.YELLOW,
            COLOR.GREEN,
            COLOR.ORANGE,
            COLOR.WHITE,
            COLOR.BLACK,
          ],
        },
      ],
    },
    [PROTECTION_SHAPE.AQUASON]: {
      options: [
        {
          ...unique,
          type: TYPE.OPTION,
          values: [
            {value: OPTION.FLOAT, disabledCallback: () => true, isPair: true},
            {
              value: OPTION.CORD_MARK,
              type: OPTION_GROUP_CHECK_TYPE.MARK_INPUT,
              maxLength: 18,
              isPair: true,
            },
          ],
        },
      ],
    },
    [PROTECTION_SHAPE.PASSTOP_EP2]: {
      options: [
        {
          ...requiredAndUnique,
          type: TYPE.COLOR,
          values: [
            COLOR.BLACK_OPAQUE,
            COLOR.GREEN_TRANSLUCENT,
            COLOR.BLUE_TRANSLUCENT,
            COLOR.COLORLESS_TRANSLUCENT,
          ].map((value) => ({value, isPair: true})),
        },
        {
          ...unique,
          type: TYPE.CORD_COLOR,
          values: [
            CORD_COLOR.BLACK,
            CORD_COLOR.GREEN,
            CORD_COLOR.BLUE,
            CORD_COLOR.ORANGE,
          ].map((value) => ({value, isPair: true})),
        },
        {
          type: TYPE.OPTION,
          values: [
            {
              value: OPTION.CORD_MARK,
              type: OPTION_GROUP_CHECK_TYPE.MARK_INPUT,
              maxLength: 18,
              disabledCallback: (options, index) =>
                !options
                  .filter(({name}) =>
                    [
                      CORD_COLOR.BLACK,
                      CORD_COLOR.GREEN,
                      CORD_COLOR.BLUE,
                      CORD_COLOR.ORANGE,
                    ].includes(name as CORD_COLOR),
                  )
                  .find(({selected}) => selected[index]),
              isPair: true,
            },
            {
              value: OPTION.CLOTHES_PIN,
              isPair: true,
            },
          ],
        },
      ],
    },
    [PROTECTION_SHAPE.PASSTOP_A]: {
      options: [],
    },
    [PROTECTION_SHAPE.PASSTOP_TIR_CHASSE]: {
      options: [],
    },
    [PROTECTION_SHAPE.PIANISSIMO]: {
      ...requiredAndUnique,
      type: TYPE.FILTER,
      ...Object.fromEntries(
        [FILTER.DB_10, FILTER.DB_15, FILTER.DB_25, FILTER.DB_30].map<
          [FILTER, ProductCategoryType]
        >((filter) => [
          filter,
          {
            options: [
              {
                ...requiredAndUnique,
                type: TYPE.COLOR,
                values: [
                  COLOR.ROSE_TRANSLUCENT,
                  COLOR.BLUE_PHOSOPHORESCENT,
                  COLOR.RED_PHOSOPHORESCENT,
                  COLOR.ORANGE_PHOSOPHORESCENT,
                  COLOR.YELLOW_PHOSOPHORESCENT,
                  COLOR.GREEN_PHOSOPHORESCENT,
                  COLOR.PINK_PHOSOPHORESCENT,
                  COLOR.WHITE,
                  COLOR.BLACK,
                ],
              },
              {
                type: TYPE.OPTION,
                values: [
                  {
                    value: OPTION.WITHOUT_HANDLE,
                    radioGroup: 'cord',
                  },
                  ...[OPTION.EAR_BUD_WHITE, OPTION.EAR_BUD_BLACK].map<
                    OptionValueMap
                  >((value) => ({
                    value,
                    type: OPTION_GROUP_CHECK_TYPE.RADIO,
                    radioGroup: 'earbud',
                    isPair: true,
                  })),
                ],
              },
            ],
          },
        ]),
      ),
    },
    [PROTECTION_SHAPE.EARBACK_MUSIC]: {
      options: [
        {
          ...requiredAndUnique,
          type: TYPE.EARBACK_PRODUCT,
          values: [
            EARBACK_PRODUCT.ONE,
            EARBACK_PRODUCT.TWO,
            EARBACK_PRODUCT.THREE,
            EARBACK_PRODUCT.THREE_T,
            EARBACK_PRODUCT.FOUR_T,
          ],
        },
        {
          ...requiredAndUnique,
          type: TYPE.COLOR,
          values: [
            ...[COLOR.BLACK, COLOR.WHITE, COLOR.YELLOW_FLUO].map<
              OptionValueMap
            >((value) => ({
              value,
              type: OPTION_GROUP_CHECK_TYPE.RADIO,
              radioGroup: 'color',
              disabledCallback: (options, index) =>
                !!options
                  .filter(({name}) =>
                    [EARBACK_COLORATION.INTEGRAL].includes(
                      name as EARBACK_COLORATION,
                    ),
                  )
                  .find(({selected}) => selected[index]),
            })),
            ...[
              COLOR.TRANSLUCENT,
              COLOR.ORANGE,
              COLOR.GREEN,
              COLOR.BLUE,
              COLOR.RED,
              COLOR.PURPLE,
            ].map<OptionValueMap>((value) => ({
              value,
              type: OPTION_GROUP_CHECK_TYPE.RADIO,
              radioGroup: 'color',
            })),
          ],
        },
        {
          ...requiredAndUnique,
          type: TYPE.EARBACK_COLORATION,
          values: [
            ...[EARBACK_COLORATION.PLATE].map<OptionValueMap>((value) => ({
              value,
              type: OPTION_GROUP_CHECK_TYPE.RADIO,
              radioGroup: 'earbackColor',
            })),
            ...[EARBACK_COLORATION.INTEGRAL].map<OptionValueMap>((value) => ({
              value,
              type: OPTION_GROUP_CHECK_TYPE.RADIO,
              radioGroup: 'earbackColor',
              disabledCallback: (options, index) =>
                !!options
                  .filter(({name}) =>
                    [COLOR.BLACK, COLOR.WHITE, COLOR.YELLOW_FLUO].includes(
                      name as COLOR,
                    ),
                  )
                  .find(({selected}) => selected[index]),
            })),
          ],
        },
        {
          type: TYPE.OPTION,
          values: [
            {
              value: OPTION.LASER_MARK,
              type: OPTION_GROUP_CHECK_TYPE.MARK_INPUT,
              maxLength: 18,
              isPair: true,
            },
            OPTION.LOGO,
          ],
        },
      ],
    },
    [PROTECTION_SHAPE.EAR]: {},
    [PROTECTION_SHAPE.PASSTOP_OS]: {
      options: [
        {
          ...requiredAndUnique,
          type: TYPE.PASSTOP_OS_PRODUCT_TYPE,
          values: [
            PASSTOP_OS_PRODUCT_TYPE.S,
            PASSTOP_OS_PRODUCT_TYPE.S1,
            PASSTOP_OS_PRODUCT_TYPE.S2,
            PASSTOP_OS_PRODUCT_TYPE.S3,
          ],
        },
        {
          ...requiredAndUnique,
          type: TYPE.COLOR,
          values: [COLOR.ROSE_TRANSLUCENT, COLOR.PASSTOP_OR_BLUE],
        },
        {
          type: TYPE.OPTION,
          values: [OPTION.CONQUE, OPTION.METAL_DETECTION],
        },
      ],
    },
    [PROTECTION_SHAPE.STOPGUN_E]: {
      options: [],
    },
    [PROTECTION_SHAPE.STOPGUN_FLEX]: {
      options: [],
    },
    [PROTECTION_SHAPE.PASSTOP_STOPGUN]: {
      options: [],
    },
    [PROTECTION_SHAPE.OREILLETTE]: {
      options: [],
    },
    [PROTECTION_SHAPE.PASSTOP_OS_CONQUE]: {
      options: [],
    },
    [PROTECTION_SHAPE.OBTURATEUR_COM]: {
      options: [],
    },
    [PROTECTION_SHAPE.SECRETEAR]: {
      options: [],
    },
  },
};
